@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

/* General Styles */
.services-container {
  font-family: 'Roboto', sans-serif;
  color: #333;
  background-color: #f8f9fa;
  width: 100%;
  margin: 0;
  padding: 0;
}

/* Hero Section */
.services-hero-section {
  position: relative;
  text-align: center;
  color: white;
  height: 60vh;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../../Images/carousel-1.jpg') no-repeat center center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100%;
  margin: 0;
}

.services-hero-section h1 {
  font-size: 3rem;
  font-weight: bold;
  margin: 0;
  padding: 1rem;
  text-shadow: 2px 2px 4px rgba(239, 105, 3, 0.5); /* Subtle shadow for better text visibility */
  text-align: center;
  z-index: 1; /* Ensure text is on top */
  animation: fadeInUp 1s forwards; /* Fade-in effect */
  color: #fff; /* Ensure the color contrasts with the background */
}

/* Services Section */
.services-section {
  padding: 5rem 0;
  background: #f8f9fa; /* Light background color for the services section */
  opacity: 0; /* Initially hidden */
  transform: translateY(20px); /* Start slightly below */
  animation: none; /* No animation initially */
}

/* Keyframes for fade-in and slide-up effect */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Animation class to be added */
.services-section.animate {
  animation: fadeInUp 1s ease-out forwards;
}

/* Service Cards */
.service-card {
  border-radius: 15px; /* Rounded corners */
  background: #fff; /* Card background color */
  transition: all 0.3s ease-in-out; /* Smooth transition for hover effects */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Card shadow */
}

.service-card:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
  transform: translateY(-5px); /* Slight lift effect on hover */
}

/* Icons */
.service-icon {
  font-size: 3rem; /* Icon size */
}

.service-card .h4 {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 15px;
}

.service-card .text-muted {
  color: #6c757d; /* Text color for muted text */
  font-size: 1rem;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .services-section {
    padding: 3rem 0;
  }

  .service-card .h4 {
    font-size: 1.25rem; /* Adjust title size on smaller screens */
  }

  .services-hero-section h2 {
    font-size: 2rem; /* Adjust heading size on smaller screens */
  }

  .services-hero-section p {
    font-size: 1rem; /* Adjust text size on smaller screens */
  }
}
