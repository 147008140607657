.react-header-container {
    position: relative;
    height: 350px; /* Default height for desktop */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .react-header-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1; /* Ensure image is behind the text */
    filter: blur(5px); /* Apply a slight blur */
    opacity: 0.8; /* Slight transparency for a more elegant look */
}

  
  .react-overlay-text {
    background-color: rgba(0, 0, 0, 0.6); /* Dark semi-transparent background */
    padding: 20px;
    border-radius: 10px;
    max-width: 90%; /* Ensures the text overlay doesn't overflow on smaller screens */
    color: white; /* White text for visibility */
    z-index: 1;
    position: relative; /* Ensure positioning relative to its container */
    text-align: center; /* Center text inside the overlay */
  }
  
  .react-overlay-text h1 {
    font-size: 2rem; /* Default size */
    font-weight: 700;
    margin-bottom: 10px;
  }
  
  .react-overlay-text p {
    font-size: 1rem; /* Default size */
    margin-top: 10px;
  }
  
  /* Media queries for responsiveness */
  @media (max-width: 1024px) {
    .react-header-container {
      height: 300px; /* Reduce height for tablets */
    }
    
    .react-overlay-text h1 {
      font-size: 1.75rem; /* Slightly smaller heading on tablets */
    }
  
    .react-overlay-text p {
      font-size: 0.95rem; /* Slightly smaller paragraph text */
    }
  }
  
  @media (max-width: 768px) {
    .react-header-container {
      height: 250px; /* Adjust height for tablets and smaller screens */
    }
    
    .react-overlay-text {
      padding: 15px; /* Reduce padding to ensure text fits */
    }
    
    .react-overlay-text h1 {
      font-size: 1.5rem; /* Smaller heading */
    }
  
    .react-overlay-text p {
      font-size: 0.9rem; /* Smaller paragraph text */
    }
  }
  
  @media (max-width: 576px) {
    .react-header-container {
      height: 200px; /* Adjust height for mobile devices */
      padding: 5px; /* Reduce padding to ensure text fits */
    }
  
    .react-overlay-text {
      padding: 10px; /* Reduce padding further for small devices */
      max-width: 100%; /* Allow the text to take full width if needed */
    }
  
    .react-overlay-text h1 {
      font-size: 1.2rem; /* Smaller heading on mobile */
    }
  
    .react-overlay-text p {
      font-size: 0.8rem; /* Smaller paragraph text */
    }
  }
  







  .react-info-section {
    padding: 60px 20px;
    background: linear-gradient(to right, #f8f9fa, #e9ecef); /* Gradient background for a modern look */
    color: #333; /* Dark text color for readability */
    position: relative; /* For positioning pseudo-elements */
    overflow: hidden; /* Ensure any overflow is hidden */
  }
  
  .react-info-section .container {
    max-width: 1200px;
    margin: 0 auto;
    position: relative; /* Ensure it's above the pseudo-element */
    z-index: 2; /* Above the overlay */
  }
  
  .react-info-section h2 {
    font-size: 2.5rem; /* Large heading for impact */
    font-weight: 700; /* Bold font weight for emphasis */
    margin-bottom: 10px; /* Space below the heading */
    color: #0e6dda; /* Dark color for headings */
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle text shadow for better readability */
    line-height: 1.3; /* Tight line height for headings */
    text-align: center;
  }
  
  .react-info-section h3 {
    font-size: 1.5rem; /* Subheading size */
    font-weight: 500; /* Medium font weight */
    margin-bottom: 20px; /* Space below the subheading */
    color: #495057; /* Slightly lighter dark color for subheadings */
    text-align: center;
  }
  
  .react-info-section p {
    font-size: 1.125rem; /* Slightly larger paragraph text for readability */
    line-height: 1.8; /* Increased line height for better readability */
    color: #010101; /* Softer dark color for text */
    max-width: 800px; /* Limit text width for readability */
    margin: 0 auto; /* Center text in the container */
    text-align: center; /* Center-align text for consistency */
    padding: 0 20px; /* Horizontal padding for text */
    position: relative; /* For positioning text effects */
    z-index: 2; /* Ensure text is above any overlay */
  }
  
  /* Media queries for responsive heading sizes */
  @media (max-width: 1024px) {
    .react-info-section h2 {
      font-size: 2rem; /* Adjust size for larger tablets */
    }
  
    .react-info-section h3 {
      font-size: 1.5rem; /* Adjust size for larger tablets */
    }
  }
  
  @media (max-width: 768px) {
    .react-info-section h2 {
      font-size: 1.75rem; /* Smaller heading size for tablets */
    }
  
    .react-info-section h3 {
      font-size: 1.25rem; /* Smaller subheading size for tablets */
    }
  
    .react-info-section p {
      font-size: 1rem; /* Slightly smaller paragraph text for tablets */
    }
  }
  
  @media (max-width: 576px) {
    .react-info-section h2 {
      font-size: 1.4rem; /* Even smaller heading size for mobile devices */
    }
  
    .react-info-section h3 {
      font-size: 1rem; /* Even smaller subheading size for mobile devices */
    }
  
    .react-info-section p {
      font-size: 0.9rem; /* Smaller paragraph text for mobile devices */
    }
  }
  





  /* New Section with image on left */
  .react-fast-implementation {
    padding: 60px 20px;
    background-color: #f1f3f5; /* Light background color for contrast */
    color: #333; /* Dark text color for readability */
  }
  
  .react-fast-implementation .container {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-wrap: wrap; /* Ensure responsiveness */
  }
  
  .react-fast-img {
    flex: 1;
    padding-right: 20px; /* Space between image and text */
  }
  
  .react-fast-img img {
    width: 100%; /* Make image responsive */
    border-radius: 10px; /* Rounded corners for the image */
  }
  
  .react-fast-text {
    flex: 1;
    padding-left: 20px; /* Space between text and image */
  }
  
  .react-fast-text h2 {
    font-size: 2rem; /* Heading size */
    font-weight: 700; /* Bold font weight for emphasis */
    margin-bottom: 10px;
    color: #0e6dda /* Dark color for headings */
  }
  
  .react-fast-text p {
    font-size: 1.125rem; /* Slightly larger paragraph text for readability */
    line-height: 1.8; /* Increased line height for better readability */
    color: #495057; /* Softer dark color for text */
  }
  
  /* Media queries for responsive design */
  @media (max-width: 1024px) {
    .react-fast-implementation .container {
      flex-direction: column;
    }
  
    .react-fast-img, .react-fast-text {
      padding: 0;
      margin-bottom: 20px; /* Space between image and text on small screens */
    }
  
    .react-fast-img {
      padding-right: 0; /* Remove right padding on small screens */
    }
  
    .react-fast-text {
      padding-left: 0; /* Remove left padding on small screens */
    }
  }
  
  @media (max-width: 768px) {
    .react-fast-text h2 {
      font-size: 1.75rem; /* Smaller heading size for tablets */
    }
  
    .react-fast-text p {
      font-size: 1rem; /* Slightly smaller paragraph text for tablets */
    }
  }
  
  @media (max-width: 576px) {
    .react-fast-text h2 {
      font-size: 1.4rem; /* Even smaller heading size for mobile devices */
    }
  
    .react-fast-text p {
      font-size: 0.9rem; /* Smaller paragraph text for mobile devices */
    }
  }
  



  /* Section with three colums */

  .react-features {
    padding: 60px 20px;
    background-color: #f9f9f9; /* Light background for contrast */
    color: #333; /* Dark text color for readability */
  }
  
  .react-feature-item {
    text-align: center; /* Center-align text */
    margin-bottom: 30px; /* Space between items */
  }
  
  .react-feature-item h3 {
    font-size: 1.75rem; /* Heading size */
    font-weight: 700; /* Bold font weight for emphasis */
    margin-bottom: 10px;
    color: #0e6dda; /* Dark color for headings */
  }
  
  .react-feature-item p {
    font-size: 1rem; /* Paragraph text size */
    line-height: 1.6; /* Line height for readability */
    color: #030303; /* Softer dark color for text */
  }
  
  /* Responsive design */
  @media (max-width: 768px) {
    .react-feature-item h3 {
      font-size: 1.5rem; /* Smaller heading size for tablets */
    }
  
    .react-feature-item p {
      font-size: 0.9rem; /* Slightly smaller paragraph text for tablets */
    }
  }
  
  @media (max-width: 576px) {
    .react-feature-item h3 {
      font-size: 1.2rem; /* Smaller heading size for mobile devices */
    }
  
    .react-feature-item p {
      font-size: 0.8rem; /* Smaller paragraph text for mobile devices */
    }
  }
  



 /* Section with three colums again */
 .react-advantages {
    padding: 60px 20px;
    background-color: #f8f9fa; /* Light grey background for overall section */
  }
  
  .react-advantage-item {
    text-align: center; /* Center-align text */
    padding: 20px; /* Add padding inside each column */
    border-radius: 10px; /* Rounded corners for a modern look */
    color: #fff; /* White text color for contrast */
    margin-bottom: 30px; /* Space between items */
    transition: background-color 0.3s ease; /* Smooth transition for hover effect */
  }
  
  .react-advantage-item .icon {
    margin-bottom: 15px; /* Space between icon and text */
  }
  
  .react-advantage-item .icon i {
    color: #fff; /* Icon color to match text */
  }
  
  .react-advantage-item h3 {
    font-size: 1.75rem; /* Heading size */
    font-weight: 700; /* Bold font weight for emphasis */
    margin-bottom: 10px;
  }
  
  .react-advantage-item p {
    font-size: 1rem; /* Paragraph text size */
    line-height: 1.6; /* Line height for readability */
  }
  
  /* Background colors for each column */
  .bg-color-one {
    background-color: #007bff; /* Blue background */
  }
  
  .bg-color-two {
    background-color: #28a745; /* Green background */
  }
  
  .bg-color-three {
    background-color: #dc3545; /* Red background */
  }
  
  /* Responsive design */
  @media (max-width: 768px) {
    .react-advantage-item h3 {
      font-size: 1.5rem; /* Smaller heading size for tablets */
    }
  
    .react-advantage-item p {
      font-size: 0.9rem; /* Slightly smaller paragraph text for tablets */
    }
  }
  
  @media (max-width: 576px) {
    .react-advantage-item h3 {
      font-size: 1.2rem; /* Smaller heading size for mobile devices */
    }
  
    .react-advantage-item p {
      font-size: 0.8rem; /* Smaller paragraph text for mobile devices */
    }
  }
  



/* New section with development services */
.react-development-services {
    padding: 60px 20px;
    background: linear-gradient(to right, #e0f7fa, #0e6dda); /* Gradient background */
    color: #333; /* Dark text color for readability */
  }
  
  .react-development-services h2 {
    font-size: 2.5rem; /* Larger heading size */
    font-weight: 700; /* Bold font weight */
    margin-bottom: 20px;
    text-align: center; /* Center-align the heading */
    position: relative;
    color: #f9fafc;
  
  }
  
  .react-development-services h2::after {
    content: '';
    display: block;
    width: 60px;
    height: 5px;
    background: #0e6dda; /* Accent color */
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .react-development-services p {
    font-size: 1.125rem; /* Slightly larger paragraph text */
    line-height: 1.6; /* Line height for readability */
    text-align: center; /* Center-align the paragraph */
    margin-bottom: 30px;
    max-width: 800px; /* Restrict width for better readability */
    margin-left: auto;
    margin-right: auto;
  }
  
  .react-services-list {
    list-style: none; /* Remove default list styling */
    padding: 0; /* Remove default padding */
    margin: 0; /* Remove default margin */
  }
  
  .react-services-list li {
    font-size: 1.125rem; /* Font size for list items */
    margin-bottom: 20px; /* Space between items */
    padding: 15px 20px; /* Padding inside each item */
    border-radius: 10px; /* Rounded corners for each item */
    background-color: #ffffff; /* White background for items */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth transitions */
    display: flex;
    align-items: center;
  }
  
  .react-services-list li i {
    font-size: 1.5rem; /* Larger icon size */
    color: #00796b; /* Icon color to match accent */
    margin-right: 15px; /* Space between icon and text */
  }
  
  .react-services-list li:hover {
    background-color: #f0f0f0; /* Light grey background on hover */
    transform: translateY(-5px); /* Slight lift effect on hover */
  }
  
  /* Responsive design */
  @media (max-width: 768px) {
    .react-development-services h2 {
      font-size: 2rem; /* Smaller heading size for tablets */
    }
  
    .react-development-services p {
      font-size: 1rem; /* Smaller paragraph text for tablets */
    }
    
    .react-services-list li {
      font-size: 1rem; /* Smaller font size for list items on tablets */
    }
  }
  
  @media (max-width: 576px) {
    .react-development-services h2 {
      font-size: 1.75rem; /* Smaller heading size for mobile devices */
    }
  
    .react-development-services p {
      font-size: 0.875rem; /* Smaller paragraph text for mobile devices */
    }
  
    .react-services-list li {
      font-size: 0.875rem; /* Smaller font size for list items on mobile devices */
    }
  }
  




/* New section with benefits of ReactJS development */
.react-benefits {
    padding: 60px 20px;
    background: linear-gradient(135deg, #f9f9f9, #e0e0e0); /* Subtle gradient background */
    color: #333; /* Dark text color for readability */
  }
  
  .react-benefits h2 {
    font-size: 2.5rem; /* Larger heading size */
    font-weight: 700; /* Bold font weight */
    margin-bottom: 40px; /* Increased margin for better spacing */
    text-align: center; /* Center-align the heading */
    position: relative;
    
    color: #0e6dda; /* Dark text color */
  }
  
  .react-benefits h2::after {
    content: '';
    display: block;
    width: 80px; /* Slightly wider underline */
    height: 6px; /* Slightly thicker underline */
    background: #0e6dda; /* Accent color */
    position: absolute;
    bottom: -15px; /* Increased space below heading */
    left: 50%;
    transform: translateX(-50%);
    border-radius: 5px; /* Rounded corners for the underline */
  }
  
  .react-benefits-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px; /* Space between items */
  }
  
  .react-benefit-item {
    background: #ffffff; /* White background for each item */
    padding: 20px;
    border-radius: 15px; /* Rounded corners */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); /* Enhanced shadow for depth */
    flex: 1 1 calc(33.333% - 20px); /* Three columns layout with gaps */
    transition: transform 0.3s ease, box-shadow 0.3s ease, background 0.3s ease; /* Smooth transitions */
    position: relative;
    overflow: hidden; /* Hide overflow for the pseudo-element effect */
  }
  
  .react-benefit-item::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 121, 107, 0.1); /* Light overlay effect */
    z-index: 0; /* Send the overlay behind the content */
    transition: background 0.3s ease;
  }
  
  .react-benefit-item i {
    font-size: 2.5rem; /* Larger icon size */
    color: #0e6dda; /* Icon color to match accent */
    margin-bottom: 20px; /* Space between icon and heading */
    position: relative;
    z-index: 1; /* Ensure icon appears above overlay */
  }
  
  .react-benefit-item h3 {
    font-size: 1.5rem; /* Larger heading size */
    margin-bottom: 15px; /* Increased space between heading and paragraph */
    z-index: 1; /* Ensure heading appears above overlay */
  }
  
  .react-benefit-item p {
    font-size: 1.1rem; /* Slightly larger paragraph text size */
    line-height: 1.8; /* Increased line height for readability */
    z-index: 1; /* Ensure paragraph appears above overlay */
  }
  
  .react-benefit-item:hover {
    transform: translateY(-10px); /* Enhanced lift effect on hover */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.25); /* Stronger shadow on hover */
  }
  
  .react-benefit-item:hover::before {
    background: rgba(0, 121, 107, 0.2); /* Darker overlay effect on hover */
  }
  
  /* Responsive design */
  @media (max-width: 768px) {
    .react-benefit-item {
      flex: 1 1 calc(50% - 20px); /* Two columns layout on tablets */
    }
  }
  
  @media (max-width: 576px) {
    .react-benefit-item {
      flex: 1 1 100%; /* Single column layout on mobile devices */
    }
  }
  