/* Adjust the main content container on all pages */
body {
    padding-top: 70px; /* Adjust this value to match the height of your fixed navbar */
  }

/* Header Section Styles */
.servicecloud-header-container {
    
    position: relative;
    width: 100%;
    height: 500px;
    overflow: hidden;
}

.servicecloud-header-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.servicecloud-header-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: white;
    text-align: center;
    padding: 2rem;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}

.servicecloud-header-overlay h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
}

.servicecloud-header-overlay p {
    font-size: 1.2rem;
    line-height: 1.6;
    margin: 0;
    max-width: 90%;
}

/* Responsive Styles */
@media (max-width: 992px) {
    .servicecloud-header-overlay {
        padding: 1.5rem;
    }

    .servicecloud-header-overlay h1 {
        font-size: 2rem;
    }

    .servicecloud-header-overlay p {
        font-size: 1rem;
    }
}

@media (max-width: 768px) {
    .servicecloud-header-overlay {
        padding: 1rem;
    }

    .servicecloud-header-overlay h1 {
        font-size: 1.75rem;
    }

    .servicecloud-header-overlay p {
        font-size: 0.9rem;
    }
}

@media (max-width: 576px) {
    .servicecloud-header-overlay {
        padding: 0.75rem;
    }

    .servicecloud-header-overlay h1 {
        font-size: 1.5rem;
    }

    .servicecloud-header-overlay p {
        font-size: 0.8rem;
    }
}







/* Benefits Section Styles */
.servicecloud-benefits {
    padding: 4rem 2rem;
    background-color: #f4f4f9;
    text-align: center;
    position: relative;
    overflow: hidden;
}

.servicecloud-benefits::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle at center, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.05));
    z-index: 0;
}

.servicecloud-benefits h2 {
    font-size: 2.5rem;
    margin-bottom: 2.5rem;
    color: #0e6dda;
    font-weight: 700;
    text-transform: uppercase;
    position: relative;
    z-index: 1;
    animation: fadeInUp 1s ease-out;
}

.servicecloud-benefits-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    position: relative;
    z-index: 1;
}

.servicecloud-benefit-card {
    background-color: #ffffff;
    border: none;
    border-radius: 12px;
    padding: 2.5rem;
    width: 320px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    text-align: left;
    transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
    position: relative;
    overflow: hidden;
    animation: fadeInUp 1.5s ease-out;
}

.servicecloud-benefit-card::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 5px;
    background: linear-gradient(90deg, rgba(0, 180, 255, 0.5), rgba(0, 180, 255, 0));
    transition: width 0.3s ease;
    z-index: 0;
}

.servicecloud-benefit-card h3 {
    font-size: 1.6rem;
    margin-bottom: 1.2rem;
    color: #222;
    position: relative;
    z-index: 1;
}

.servicecloud-benefit-card p {
    font-size: 1.1rem;
    line-height: 1.6;
    color: #555;
    position: relative;
    z-index: 1;
}

.servicecloud-benefit-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 12px 24px #0e6dda;
    background-color: #f9f9f9;
}

.servicecloud-benefit-card:hover::before {
    width: 100%;
}

/* Responsive Styles */
@media (max-width: 992px) {
    .servicecloud-benefits {
        padding: 3rem 1.5rem;
    }

    .servicecloud-benefits h2 {
        font-size: 2.5rem;
        margin-bottom: 2rem;
    }

    .servicecloud-benefit-card {
        width: 280px;
        padding: 2rem;
    }

    .servicecloud-benefit-card h3 {
        font-size: 1.4rem;
    }

    .servicecloud-benefit-card p {
        font-size: 1rem;
    }
}

@media (max-width: 768px) {
    .servicecloud-benefits {
        padding: 2rem 1rem;
    }

    .servicecloud-benefits h2 {
        font-size: 2rem;
        margin-bottom: 1.5rem;
    }

    .servicecloud-benefit-card {
        width: 250px;
        padding: 1.5rem;
    }

    .servicecloud-benefit-card h3 {
        font-size: 1.2rem;
    }

    .servicecloud-benefit-card p {
        font-size: 0.9rem;
    }
}

@media (max-width: 576px) {
    .servicecloud-benefits {
        padding: 1.5rem 0.5rem;
    }

    .servicecloud-benefits h2 {
        font-size: 1.75rem;
        margin-bottom: 1rem;
    }

    .servicecloud-benefit-card {
        width: 100%;
        max-width: 300px;
        padding: 1rem;
    }

    .servicecloud-benefit-card h3 {
        font-size: 1rem;
    }

    .servicecloud-benefit-card p {
        font-size: 0.8rem;
    }
}







/* Why Salesforce Service Cloud Section Styles */
.servicecloud-why {
    padding: 4rem 2rem;
    background: linear-gradient(135deg, #ffffff, #f0f4f8);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
}

.servicecloud-why-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 3rem;
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    z-index: 1;
    animation: fadeInUp 1s ease-out;
}

.servicecloud-why-cards {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
}

.servicecloud-why-card {
    background-color: #f7f8fa;
    border: none;
    border-radius: 12px;
    padding: 2.5rem;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    text-align: left;
    transition: transform 0.4s, box-shadow 0.4s;
    position: relative;
    overflow: hidden;
}

.servicecloud-why-card::before {
    content: "";
    position: absolute;
    top: -100%;
    left: -100%;
    width: 200%;
    height: 200%;
    background: linear-gradient(45deg, #0e6dda, #0e6dda);
    transform: rotate(45deg);
    z-index: 0;
    transition: transform 0.5s ease;
    opacity: 0.1;
}

.servicecloud-why-card:hover::before {
    transform: rotate(0deg);
}

.servicecloud-why-card h3 {
    font-size: 1.8rem;
    margin-bottom: 1rem;
    color: #333;
    position: relative;
    z-index: 1;
}

.servicecloud-why-card p {
    font-size: 1.1rem;
    line-height: 1.7;
    color: #555;
    position: relative;
    z-index: 1;
}

.servicecloud-why-card:hover {
    transform: translateY(-8px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15);
}

.servicecloud-why-image {
    flex: 1;
    max-width: 100%;
    transform: scale(1);
    transition: transform 0.4s ease;
}

.servicecloud-why-image img {
    width: 100%;
    height: auto;
    border-radius: 12px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
    transition: transform 0.4s ease;
}

.servicecloud-why-image:hover img {
    transform: scale(1.05);
}

/* Responsive Styles */
@media (max-width: 992px) {
    .servicecloud-why {
        padding: 3rem 1.5rem;
    }

    .servicecloud-why-content {
        flex-direction: column;
        gap: 2rem;
    }

    .servicecloud-why-cards {
        gap: 1.5rem;
    }

    .servicecloud-why-card {
        padding: 2rem;
    }

    .servicecloud-why-card h3 {
        font-size: 1.6rem;
    }

    .servicecloud-why-card p {
        font-size: 1rem;
    }

    .servicecloud-why-image {
        margin-top: 2rem;
    }
}

@media (max-width: 768px) {
    .servicecloud-why {
        padding: 2rem 1rem;
    }

    .servicecloud-why-content {
        gap: 1.5rem;
    }

    .servicecloud-why-cards {
        gap: 1rem;
    }

    .servicecloud-why-card {
        padding: 1.5rem;
    }

    .servicecloud-why-card h3 {
        font-size: 1.4rem;
    }

    .servicecloud-why-card p {
        font-size: 0.9rem;
    }

    .servicecloud-why-image {
        margin-top: 1.5rem;
    }
}

@media (max-width: 576px) {
    .servicecloud-why {
        padding: 1.5rem 0.5rem;
    }

    .servicecloud-why-content {
        gap: 1rem;
    }

    .servicecloud-why-cards {
        gap: 0.5rem;
    }

    .servicecloud-why-card {
        padding: 1rem;
    }

    .servicecloud-why-card h3 {
        font-size: 1.2rem;
    }

    .servicecloud-why-card p {
        font-size: 0.8rem;
    }

    .servicecloud-why-image {
        margin-top: 1rem;
    }
}








/* Why Choose Our Salesforce Service Cloud Implementation Services Section Styles */
.servicecloud-why-choose {
    padding: 4rem 2rem;
    background: linear-gradient(135deg, #f3f4f7, #e2e5ec);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
}

.servicecloud-why-choose::before {
    content: "";
    position: absolute;
    top: -50%;
    right: -50%;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle at center, rgba(0, 128, 255, 0.932), rgba(24, 43, 51, 0));
    z-index: 0;
    transform: rotate(45deg);
}

.servicecloud-why-choose-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2.5rem;
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    z-index: 1;
    animation: fadeInUp 1s ease-out;
}

.servicecloud-why-choose-image {
    flex: 1;
    max-width: 100%;
    transform: scale(1);
    transition: transform 0.4s ease;
}

.servicecloud-why-choose-image img {
    width: 100%;
    height: auto;
    border-radius: 12px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
    transition: transform 0.4s ease;
}

.servicecloud-why-choose-image:hover img {
    transform: scale(1.05);
}

.servicecloud-why-choose-text {
    flex: 1;
}

.servicecloud-why-choose-text h2 {
    font-size: 2rem;
    margin-bottom: 1.5rem;
    color: #0e6dda;
    font-weight: 700;
    text-transform: uppercase;
    position: relative;
    z-index: 1;
    animation: fadeInUp 1s ease-out;
}

.servicecloud-why-choose-text p {
    font-size: 1rem;
    line-height: 1.7;
    color: #333;
    position: relative;
    z-index: 1;
}

/* Animation Keyframes */
@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Responsive Styles */
@media (max-width: 992px) {
    .servicecloud-why-choose {
        padding: 3rem 1.5rem;
    }

    .servicecloud-why-choose-content {
        flex-direction: column;
        gap: 2rem;
    }

    .servicecloud-why-choose-image {
        margin-bottom: 2rem;
    }

    .servicecloud-why-choose-text h2 {
        font-size: 2rem;
    }

    .servicecloud-why-choose-text p {
        font-size: 1rem;
    }
}

@media (max-width: 768px) {
    .servicecloud-why-choose {
        padding: 2rem 1rem;
    }

    .servicecloud-why-choose-content {
        gap: 1.5rem;
    }

    .servicecloud-why-choose-text h2 {
        font-size: 1.8rem;
    }

    .servicecloud-why-choose-text p {
        font-size: 0.9rem;
    }
}

@media (max-width: 576px) {
    .servicecloud-why-choose {
        padding: 1.5rem 0.5rem;
    }

    .servicecloud-why-choose-content {
        gap: 1rem;
    }

    .servicecloud-why-choose-image {
        margin-bottom: 1rem;
    }

    .servicecloud-why-choose-text h2 {
        font-size: 1.5rem;
    }

    .servicecloud-why-choose-text p {
        font-size: 0.8rem;
    }
}
