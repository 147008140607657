/* Ensure the Contact container uses full width and padding */
.Contact {
  width: 100%;
  padding: 2rem; /* Adjust padding as needed */
}

/* Ensure full height for sections in a row */
.Contact .row {
  align-items: stretch;
}

/* Make contact-info, contact-form, and map take equal height */
.Contact .contact-info,
.Contact .contact-form,
.Contact .map {
  display: flex;
  flex-direction: column;
  height: 100%;
}

@keyframes fadeInBg {
  from {
    opacity: 0;
    background-color: transparent;
  }
  to {
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.1); /* Light background color */
  }
}

/* Image styling to fit container */
.contact-info img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Adjust image to fill the container */
  animation: fadeInBg 2s ease-in-out;
}

@keyframes lightSpeedIn {
  0% {
    transform: translateX(100%) skewX(-30deg);
    opacity: 0;
  }
  100% {
    transform: translateX(0) skewX(0deg);
    opacity: 1;
  }
}

/* Form styling */
.contact-form {
  background-color: #fff; /* Optional: adjust background color */
  border: 1px solid #ddd; /* Optional: border around the form */
  animation: lightSpeedIn 1s ease-out;
}

.contact-form .form-control {
  border-radius: 0.25rem; /* Adjust border radius as needed */
}

.contact-form .btn {
  border-radius: 0.25rem; /* Adjust button border radius as needed */
  font-size: 0.875rem; /* Smaller font size */
  padding: 0.5rem 0.20rem; /* Reduce padding for a shorter button */
  width: 150px; /* Set a fixed width for the button */
  text-align: center; /* Ensure text is centered */
}

/* Styling for required field indicator */
.form-group .required {
  color: red;
}

/* Invalid feedback styling */
.invalid-feedback {
  color: red; /* Ensure error messages are visible in red */
}


/* Typography for headings */
.Contact .MuiTypography-root {
  margin-bottom: 1rem;
}

/* Social media icon styling */
/* Social media icon styling */
.social-icon {
  font-size: 2rem; /* Adjust size as needed */
  color: #0a0a0a; /* Default color for social media icons */
  transition: color 0.3s ease; /* Smooth transition for color change */
  position: relative; /* To position the dialogue box relative to the icon */
}

/* LinkedIn */
.social-icon.linkedin {
  color: #0077b5;
}

.social-icon.linkedin:hover {
  color: #005c99;
}

/* Email */
.social-icon.email {
  color: #c7c7c3;
}

.social-icon.email:hover {
  color: #555555;
}

/* First Upwork - Vinod's Profile */
.social-icon.upwork {
  color: #00a77d;
  position: relative;
}

.social-icon.upwork:hover {
  color: #008c6e;
}

/* First Upwork - Vinod's Profile */
.upwork-1:hover::after {
  content: "Vinod's Profile"; /* Text to be displayed */
  position: absolute;
  bottom: -80%; /* Position above the icon */
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8); /* Background for the message */
  color: #fff;
  padding: 0.2rem 0.4rem; /* Reduced padding */
  font-size: 0.8rem; /* Smaller font size */
  border-radius: 2px;
  white-space: nowrap;
  z-index: 1000;
  opacity: 1;
  pointer-events: none;
  transition: opacity 0.3s ease;
}

/* Second Upwork - Ritika's Profile */
.social-icon.upwork-alt {
  color: #f71c08;
  position: relative;
}

.social-icon.upwork-alt:hover {
  color: #f71c08;
}

/* Second Upwork - Ritika's Profile */
.upwork-2:hover::after {
  content: "Ritika's Profile"; /* Text to be displayed */
  position: absolute;
  bottom: -80%; /* Position above the icon */
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8); /* Background for the message */
  color: #fff;
  padding: 0.2rem 0.4rem; /* Reduced padding */
  font-size: 0.8rem; /* Smaller font size */
  border-radius: 2px;
  white-space: nowrap;
  z-index: 1000;
  opacity: 1;
  pointer-events: none;
  transition: opacity 0.3s ease;
}








/* Responsive adjustments */
@media (max-width: 768px) {
  .Contact .contact-info,
  .Contact .contact-form,
  .Contact .map {
    height: auto; /* Allow height to adjust for smaller screens */
  }

  .contact-info img,
  .map iframe {
    height: auto; /* Allow content to scale naturally */
  }
}
