.admin-developer-header {
  position: relative;
  text-align: center;
  color: #fff;
  overflow: hidden;
}

.admin-developer-img {
  width: 100%;
  height: auto; /* Adjust height to maintain aspect ratio */
  max-height: 400px; /* Ensure it does not exceed the max height */
  display: block;
  object-fit: cover;
}

.admin-developer-overlay-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px; /* Adjust padding as needed */
  background: rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  max-width: 90%; /* Ensure it does not overflow the container */
  box-sizing: border-box;
  text-align: center;
  white-space: normal; /* Allow text to wrap */
  display: inline-block; /* Allow overlay to fit content */
  overflow: visible; /* Ensure content is not clipped */
  min-height: 100px; /* Set a minimum height if needed */
}

.admin-developer-title {
  font-size: 2.5rem;
  margin-bottom: 10px;
  overflow-wrap: break-word;
}

.admin-developer-description {
  font-size: 1.125rem;
  line-height: 1.6;
  overflow-wrap: break-word;
}











  

 /* Support Services Section */
.admin-developer-support {
    padding: 60px 0; /* Increased padding for more space */
    background-color: #f8f9fa; /* Light background color for contrast */
    border-radius: 12px; /* Rounds the corners of the entire section */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  }
  
  .admin-developer-row {
    display: flex;
    align-items: center;
    margin-top: 0; /* Removed top margin */
  }
  
  .admin-developer-col-left {
    padding-right: 20px; /* Increased padding for more space */
    display: flex;
    justify-content: center; /* Center the image */
  }
  
  .admin-developer-col-right {
    padding-left: 20px; /* Increased padding for more space */
  }
  
  .admin-developer-support-img {
    width: 80%; /* Adjusted width for a better layout */
    max-height: 300px; /* Shorter image height */
    object-fit: cover;
    border-radius: 12px; /* More rounded corners for a modern look */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow around the image */
  }
  
  .admin-developer-subtitle {
    font-size: 2.2rem; /* Slightly larger font size */
    margin-bottom: 20px;
    color: #2c3e50; /* Darker color for better contrast */
    font-weight: 700; /* Bold font for emphasis */
  }
  
  .admin-developer-text {
    font-size: 1.2rem; /* Slightly larger font size */
    color: #555; /* Medium color for the paragraph text */
    line-height: 1.8; /* Increased line height for better readability */
  }

  /* What Sets Us Apart Section */
.admin-developer-unique {
    padding: 60px 20px;
    background-color: #e9ecef; /* Light grey background for contrast */
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    margin-top: 40px;
  }
  
  .admin-developer-unique-title {
    font-size: 2.2rem;
    margin-bottom: 20px;
    color: #2c3e50;
    font-weight: 700;
    text-align: center;
  }
  
  .admin-developer-unique-description {
    font-size: 1.2rem;
    color: #555;
    line-height: 1.8;
    text-align: center;
    margin-bottom: 40px; /* Spacing before the cards */
  }
  
  .admin-developer-unique-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Center the cards */
    gap: 20px; /* Space between cards */
  }
  
  .admin-developer-card {
    background: white;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
    width: 250px; /* Fixed width for cards */
    max-width: 100%; /* Responsive card width */
  }
  
  .admin-developer-card-img {
    width: 100%;
    height: 120px; /* Adjusted height for shorter images */
    object-fit: contain; /* Ensures the entire image is visible */
    border-radius: 8px;
    margin-bottom: 15px;
  }
  
  
  .admin-developer-card-title {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #333;
  }
  
  .admin-developer-card-text {
    font-size: 1rem;
    color: #555;
    line-height: 1.6;
  }


  /* Additional Benefits Section */
.admin-developer-benefits {
    padding: 60px 20px;
    background: #f7f9fc;
    border-radius: 10px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    margin-top: 40px;
  }
  
  .admin-developer-benefits-title {
    font-size: 2.4rem;
    margin-bottom: 20px;
    color: #333;
    font-weight: 700;
    text-align: center;
  }
  
  .admin-developer-benefits-content {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
  }
  
  .admin-developer-benefit-item {
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
    width: calc(50% - 30px); /* Adjusts for gap */
    max-width: 100%;
  }
  
  .admin-developer-benefit-title {
    font-size: 1.8rem;
    margin-bottom: 15px;
    color: #333;
  }
  
  .admin-developer-benefit-text {
    font-size: 1rem;
    color: #555;
    line-height: 1.6;
  }
  