/* Adjust the main content container on all pages */
body {
  padding-top: 70px; /* Adjust this value to match the height of your fixed navbar */
}

/* Header Section Styling */
.manufacturing-cloud-header {
  position: relative;
  background: linear-gradient(135deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.4)), url('../Services pages/images/manufacturing.jpg') no-repeat center center/cover;
  height: 450px; /* Increased height for more impact */
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
  animation: fadeIn 1s ease-in-out;
}

.manufacturing-cloud-overlay {
  padding: 20px;
  border-radius: 15px;
  position: relative;
  z-index: 1;
  max-width: 800px; /* Increased max-width for the overlay */
  margin: 0 auto;
}

.manufacturing-cloud-header h1 {
  font-size: 2.5rem; /* Larger font size for greater impact */
  margin-bottom: 20px;
  font-weight: 900; /* Heavier font weight for emphasis */
  text-transform: uppercase;
  letter-spacing: 3px; /* Increased letter spacing for a more dramatic effect */
  background: linear-gradient(90deg, rgb(250, 250, 252), rgba(231, 230, 230, 0.968));
  -webkit-background-clip: text;
  color: transparent;
  max-width: 95%; /* Adjusted max-width for better responsiveness */
  animation: slideInDown 1s ease-in-out;
}

.manufacturing-cloud-header p {
  font-size: 1.2rem; /* Larger font size for better readability */
  line-height: 1.6;
  color: #fdfcfa;
  max-width: 95%; /* Adjusted max-width for better responsiveness */
  animation: fadeInUp 1.2s ease-in-out;
}

/* Keyframes for advanced animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideInDown {
  from {
    transform: translateY(-40px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fadeInUp {
  from {
    transform: translateY(30px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Main Content Section */
.manufacturing-cloud-content {
  padding: 60px 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.heading-container {
  position: relative;
  padding: 40px;
  background: #f9f9f9;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;
  overflow: hidden;
  text-align: center;
}

.heading-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.heading-container h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #333;
  position: relative;
  z-index: 1;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  background: linear-gradient(90deg, #2597df, #0275f8);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.heading-container p {
  font-size: 1.2rem;
  line-height: 1.8;
  color: #060606;
  position: relative;
  z-index: 1;
  max-width: 800px;
  margin: 0 auto;
}

.manufacturing-cloud-content-item {
  background: #ffffff;
  border-radius: 12px;
  padding: 30px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease, background 0.3s ease;
}

.manufacturing-cloud-content-item::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 5px;
  height: 100%;
  background: #0b9cea;
  border-radius: 0 5px 5px 0;
}

.manufacturing-cloud-content-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 24px #0e6dda;
}

.subheading {
  font-size: 1.75rem;
  margin-bottom: 15px;
  color: #333;
  font-weight: 600;
  position: relative;
}

.subheading::after {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 50px;
  height: 4px;
  background: #0083bf;
  border-radius: 2px;
}

.section-paragraph {
  font-size: 1.125rem;
  line-height: 1.8;
  color: #555;
}

/* Responsive Design */
@media (max-width: 1200px) {
  .manufacturing-cloud-header h1 {
    font-size: 3.5rem;
  }

  .manufacturing-cloud-header p {
    font-size: 1.6rem;
  }

  .heading-container h2 {
    font-size: 2.5rem;
  }

  .heading-container p {
    font-size: 1rem;
  }
}

@media (max-width: 992px) {
  .manufacturing-cloud-header {
    height: 350px;
  }

  .manufacturing-cloud-header h1 {
    font-size: 3rem;
  }

  .manufacturing-cloud-header p {
    font-size: 1.4rem;
  }

  .heading-container {
    padding: 30px;
  }

  .heading-container h2 {
    font-size: 2rem;
  }

  .heading-container p {
    font-size: 0.9rem;
  }

  .manufacturing-cloud-content-item {
    padding: 20px;
  }

  .subheading {
    font-size: 1.5rem;
  }

  .section-paragraph {
    font-size: 1rem;
  }
}

@media (max-width: 768px) {
  .manufacturing-cloud-header {
    height: 300px;
  }

  .manufacturing-cloud-header h1 {
    font-size: 2.5rem;
  }

  .manufacturing-cloud-header p {
    font-size: 1.2rem;
  }

  .heading-container {
    padding: 20px;
  }

  .heading-container h2 {
    font-size: 1.8rem;
  }

  .heading-container p {
    font-size: 0.9rem;
  }

  .manufacturing-cloud-content-item {
    padding: 15px;
  }

  .subheading {
    font-size: 1.25rem;
  }

  .section-paragraph {
    font-size: 0.875rem;
  }
}

@media (max-width: 576px) {
  .manufacturing-cloud-header {
    height: 250px;
  }

  .manufacturing-cloud-header h1 {
    font-size: 2rem;
  }

  .manufacturing-cloud-header p {
    font-size: 1rem;
  }

  .heading-container {
    padding: 15px;
  }

  .heading-container h2 {
    font-size: 1.5rem;
  }

  .heading-container p {
    font-size: 0.8rem;
  }

  .manufacturing-cloud-content-item {
    padding: 10px;
  }

  .subheading {
    font-size: 1.125rem;
  }

  .section-paragraph {
    font-size: 0.75rem;
  }
}

/* New Section Styles */
.manufacturing-cloud-info-section {
  display: flex;
  align-items: center;
  background: linear-gradient(135deg, #05e4ec 0%, #f4f4f4 100%);
  padding: 60px 20px;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  margin: 10px 10px;
  position: relative;
  overflow: hidden;
}

.manufacturing-cloud-info-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('../Services pages/images//manufacturingpattern.jpg'); /* Replace with your pattern URL */
  opacity: 0.9;
  z-index: 0;
}

.manufacturing-cloud-info-section img {
  max-width: 100%;
  height: auto;
  max-height: 500px; /* Adjust height as needed */
  border-radius: 12px;
  margin-right: 10px;
  z-index: 1;
  object-fit: cover; /* Ensure the image covers its container without distortion */
}

.manufacturing-cloud-info-content {
  max-width: 600px;
  z-index: 1;
}

.manufacturing-cloud-info-content h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #0e6dda;
  
  text-transform: uppercase;
  position: relative;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.manufacturing-cloud-info-content p {
  font-size: 1.2rem;
  line-height: 1.8;
  color: #0a0a0a;
}

/* Responsive Design */
@media (max-width: 768px) {
  .manufacturing-cloud-info-section {
    flex-direction: column;
    text-align: center;
  }

  .manufacturing-cloud-info-section img {
    margin-right: 0;
    margin-bottom: 20px;
    max-height: 200px; /* Adjust height for smaller screens */
  }

  .manufacturing-cloud-info-content h2 {
    font-size: 2rem;
  }

  .manufacturing-cloud-info-content p {
    font-size: 1rem;
  }
}

/* Why Choose Section */
.why-choose-section {
  display: flex;
  align-items: center;
  background: #ffffff;
  padding: 60px 20px;
  border-radius: 20px;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  margin: 40px 0;
  position: relative;
  overflow: hidden;
  gap: 20px; /* Space between the image and text */
}

.why-choose-section img {
  flex: 1;
  max-width: 100%;
  border-radius: 12px;
  height: auto;
  object-fit: cover;
}

.why-choose-content {
  flex: 2;
  max-width: 600px;
}

.why-choose-content h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #0e6dda;
  
  text-transform: uppercase;
  position: relative;
}

.why-choose-content p {
  font-size: 1.2rem;
  line-height: 1.8;
  color: #090909;
}

/* Responsive Design */
@media (max-width: 768px) {
  .why-choose-section {
    flex-direction: column;
    text-align: center;
  }

  .why-choose-section img {
    margin-bottom: 20px;
    max-height: 300px; /* Adjust height for smaller screens */
  }

  .why-choose-content h2 {
    font-size: 2rem;
  }

  .why-choose-content p {
    font-size: 1rem;
  }
}
