/* Adjust the main content container on all pages */
body {
    padding-top: 70px; /* Adjust this value to match the height of your fixed navbar */
  }

/* Header Section Styling */
.health-cloud-header {
    position: relative;
    background: linear-gradient(135deg, rgba(12, 12, 12, 0.342), rgba(6, 81, 186, 0.4)), url('../Services pages/images/health2.jpg') no-repeat center center/cover;
    height: 450px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-bottom-left-radius: 100px;
    border-bottom-right-radius: 100px;
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);
    animation: fadeIn 1.5s ease-in-out;
    overflow: hidden;
}

.health-cloud-header::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
}

.health-cloud-overlay {
    padding: 30px 30px;
    border-radius: 12px;
    position: relative;
    z-index: 1;
    max-width: 800px; /* Adjust max-width for the overlay */
    margin: 0 auto;
}

.health-cloud-header h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: 2px;
    animation: slideInDown 1s ease-in-out;
    background: linear-gradient(90deg, rgb(249, 248, 248), rgb(252, 252, 252)) no-repeat;
    -webkit-background-clip: text;
    color: transparent;
    max-width: 90%; /* Adjust max-width to control the width of the text */
    margin: 0 auto;
}

.health-cloud-header p {
    font-size: 1.2rem;
    line-height: 1.4;
    animation: fadeInUp 1.2s ease-in-out;
    font-weight: 300;
    letter-spacing: 0.5px;
    max-width: 90%; /* Adjust max-width to control the width of the text */
    margin: 0 auto;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes slideInDown {
    from {
        transform: translateY(-30px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes fadeInUp {
    from {
        transform: translateY(20px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

/* Why Opt For Salesforce Health Cloud Solutions Section */
.health-cloud-new-section {
    padding: 80px 40px;
    background: white;
    text-align: center;
    border-radius: 12px;
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15);
    animation: fadeInUp 1.5s ease-in-out;
    position: relative;
    overflow: hidden;
}

.health-cloud-new-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle at top left, rgba(201, 8, 8, 0.05), transparent);
    z-index: 1;
    pointer-events: none;
}

.health-cloud-new-section h2 {
    font-size: 2.5rem;
    color: #0e6dda;
    margin-bottom: 30px;
    font-weight: 700;
    text-transform: capitalize;
    position: relative;
    display: inline-block;
    padding-bottom: 15px;
    z-index: 2;
}

.health-cloud-new-section h2::after {
    content: '';
    position: absolute;
    width: 80px;
    height: 5px;
    background-color: #0e6dda;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    border-radius: 3px;
    z-index: 2;
}

.health-cloud-intro-text {
    font-size: 1.2rem;
    color: #0f0f0f;
    margin-bottom: 60px;
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
    line-height: 1.6;
    position: relative;
    z-index: 2;
}

.health-cloud-info-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 30px;
    z-index: 2;
}

.health-cloud-info-card {
    background: #fff;
    padding: 30px;
    border-radius: 15px;
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15);
    transition: transform 0.4s ease, box-shadow 0.4s ease;
    position: relative;
    overflow: hidden;
}

.health-cloud-info-card:hover {
    transform: translateY(-15px);
    box-shadow: 0 18px 36px #0e6dda;
}

.health-cloud-info-card::before {
    content: '';
    position: absolute;
    top: -40%;
    left: -40%;
    width: 220%;
    height: 220%;
    transition: all 0.4s ease;
}

.health-cloud-info-card:hover::before {
    top: -20%;
    left: -20%;
}

.health-cloud-info-card h3 {
    font-size: 1.5rem;
    margin-bottom: 20px;
    font-weight: 600;
    color: #0e6dda;
    position: relative;
    z-index: 2;
}

.health-cloud-info-card p {
    font-size: 1.2rem;
    color: #4a4848;
    line-height: 1.8;
    position: relative;
    z-index: 2;
}

/* Integration Section Styling */
.health-cloud-integration-section {
    padding: 70px 30px;
    background: linear-gradient(135deg, rgba(233,238,247,1), rgba(237,243,255,1));
    border-radius: 12px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    position: relative;
    overflow: hidden;
    animation: fadeInUp 1.5s ease-in-out;
}

.health-cloud-integration-section::before {
    content: '';
    position: absolute;
    top: -15%;
    left: -15%;
    width: 130%;
    height: 130%;
    background: radial-gradient(circle at top right, rgba(248, 249, 250, 0.975), transparent);
    z-index: 1;
    pointer-events: none;
    transition: all 0.5s ease;
}

.health-cloud-integration-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    flex-wrap: wrap;
}

/* Text Container */
.health-cloud-integration-text {
    flex: 1;
    min-width: 300px;
    max-width: 600px;
    text-align: left;
    position: relative;
    z-index: 2;
}

.health-cloud-integration-text h2 {
    font-size: 2.3rem;
    color: #0e6dda;
    margin-bottom: 20px;
    font-weight: 700;
    position: relative;
    z-index: 2;
}

.health-cloud-integration-text p {
    font-size: 1.2rem;
    color: #555;
    line-height: 1.6;
    margin-bottom: 20px;
    position: relative;
    z-index: 2;
}

/* Image Container */
.health-cloud-integration-image {
    flex: 1;
    min-width: 300px;
    max-width: 500px;
    position: relative;
    z-index: 2;
}

.health-cloud-integration-image img {
    width: 100%;
    height: auto;
    border-radius: 12px;
    box-shadow: 0 6px 12px rgba(25, 25, 26, 0.847);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.health-cloud-integration-image img:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px #0e6dda;
}

/* Services Section Styling */
.health-cloud-services-section {
    padding: 100px 60px;
    background: linear-gradient(135deg, #fafafb, #fdfefe);
    border-radius: 15px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    animation: fadeInUp 1.5s ease-in-out;
    position: relative;
    overflow: hidden;
}

.health-cloud-services-section h2 {
    font-size: 2.5rem;
    color: #0e6dda;
    margin-bottom: 40px;
    font-weight: 700;
    text-align: center;
    position: relative;
    z-index: 2;
}

.health-cloud-services-container {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;
}

.health-cloud-service-card {
    background: #fff;
    padding: 40px;
    border-radius: 12px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    flex: 1;
    min-width: 300px;
    max-width: 400px;
    position: relative;
    overflow: hidden;
}

.health-cloud-service-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

.health-cloud-service-card h3 {
    font-size: 1.8rem;
    color: #0e6dda;
    margin-bottom: 20px;
    font-weight: 600;
    position: relative;
    z-index: 2;
}

.health-cloud-service-card p {
    font-size: 1.2rem;
    color: #444;
    line-height: 1.6;
    position: relative;
    z-index: 2;
}

/* Responsive Design Adjustments */
@media (max-width: 1200px) {
    .health-cloud-header h1 {
        font-size: 2.5rem;
    }

    .health-cloud-header p {
        font-size: 1.6rem;
    }

    .health-cloud-new-section h2 {
        font-size: 2.5rem;
    }

    .health-cloud-intro-text {
        font-size: 1.2rem;
    }
}

@media (max-width: 992px) {
    .health-cloud-header {
        height: 350px;
    }

    .health-cloud-header h1 {
        font-size: 2rem;
    }

    .health-cloud-header p {
        font-size: 1.4rem;
    }

    .health-cloud-new-section h2 {
        font-size: 2rem;
    }

    .health-cloud-intro-text {
        font-size: 1.1rem;
    }

    .health-cloud-info-container {
        grid-template-columns: 1fr;
    }

    .health-cloud-info-card {
        padding: 20px;
    }

    .health-cloud-info-card h3 {
        font-size: 1.6rem;
    }

    .health-cloud-info-card p {
        font-size: 1rem;
    }
}

@media (max-width: 768px) {
    .health-cloud-header {
        height: 300px;
    }

    .health-cloud-header h1 {
        font-size: 1.8rem;
    }

    .health-cloud-header p {
        font-size: 1.2rem;
    }

    .health-cloud-new-section h2 {
        font-size: 1.8rem;
    }

    .health-cloud-intro-text {
        font-size: 1rem;
    }

    .health-cloud-info-card {
        padding: 15px;
    }

    .health-cloud-info-card h3 {
        font-size: 1.4rem;
    }

    .health-cloud-info-card p {
        font-size: 0.9rem;
    }
}

@media (max-width: 576px) {
    .health-cloud-header {
        height: 250px;
    }

    .health-cloud-header h1 {
        font-size: 1.6rem;
    }

    .health-cloud-header p {
        font-size: 1rem;
    }

    .health-cloud-new-section h2 {
        font-size: 1.6rem;
    }

    .health-cloud-intro-text {
        font-size: 0.9rem;
    }

    .health-cloud-info-card {
        padding: 10px;
    }

    .health-cloud-info-card h3 {
        font-size: 1.2rem;
    }

    .health-cloud-info-card p {
        font-size: 0.8rem;
    }
}
