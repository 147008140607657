.consulting-services-header-section {
    position: relative;
    text-align: center;
    color: white;
    height: 70vh; /* Set the height to 50% of the viewport height */
    overflow: hidden; /* Ensures no overflow for small screens */
  }
  
  .consulting-services-header-image {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image covers the entire area without distortion */
  }
  
  .consulting-services-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Creates a transparent black overlay */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .consulting-services-overlay-text {
    text-align: center;
    padding: 20px;
    background: rgba(255, 255, 255, 0.1); /* Adds a light transparent overlay behind the text */
    border-radius: 10px; /* Rounded corners for a modern look */
  }
  
  .consulting-services-overlay-text h1 {
    font-size: 2.5rem;
    font-weight: bold;
  }
  
  .consulting-services-overlay-text p {
    font-size: 1.1rem;
    max-width: 700px;
    margin: 0 auto;
    line-height: 1.5;
  }
  
  /* Media Queries for better mobile responsiveness */
  @media (max-width: 768px) {
    .consulting-services-header-section {
      height: 30vh; /* Reduce height on smaller devices */
    }
  
    .consulting-services-overlay-text h1 {
      font-size: 2rem;
    }
  
    .consulting-services-overlay-text p {
      font-size: 1rem;
    }
  }
  
  @media (max-width: 576px) {
    .consulting-services-header-section {
      height: 25vh; /* Further reduce height on very small devices */
    }
  
    .consulting-services-overlay-text h1 {
      font-size: 1.5rem;
    }
  
    .consulting-services-overlay-text p {
      font-size: 0.9rem;
    }
  }
  




  /* New Section: Text on Left, Image on Right */
.consulting-services-section {
    padding: 50px 0; /* Adds spacing above and below the section */
  }
  
  .consulting-services-text h2 {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 20px; /* Adds space below the heading */
    color: #0e6dda;
  }
  
  .consulting-services-text p {
    font-size: 1.1rem;
    line-height: 1.6;
  }
  
/* Ensure the image is responsive with controlled height */
.consulting-services-image img {
    width: 100%;
    height: auto; /* Keeps aspect ratio */
    max-height: 400px; /* Adjust the maximum height to your preference */
    display: block; /* Ensures there’s no extra space below the image */
    margin: 0 auto; /* Centers the image */
  }
  
  
  /* Responsive Adjustments */
  @media (max-width: 768px) {
    .consulting-services-section {
      text-align: center; /* Center text for smaller screens */
    }
  
    .consulting-services-image {
      margin-top: 20px; /* Adds space between the text and image on smaller screens */
    }
  }
  
  @media (max-width: 576px) {
    .consulting-services-section {
      padding: 30px 0; /* Reduce padding on smaller screens */
    }
  
    .consulting-services-text h2 {
      font-size: 1.5rem; /* Smaller font size for heading on smaller screens */
    }
  
    .consulting-services-text p {
      font-size: 1rem; /* Smaller font size for paragraph on smaller screens */
    }
  }


  



/* Section for Delivering Success Efficiently */
.consulting-services-efficiency-section {
    padding: 60px 0;
    background-color: #f8f9fa;
    text-align: center;
  }
  
  .consulting-services-efficiency-section h2 {
    margin-bottom: 20px;
    font-size: 2rem;
    font-weight: bold;
    color: #0e6dda;
  }
  
  .consulting-services-efficiency-section p {
    font-size: 1rem;
    color: #666;
    line-height: 1.6;
  }
  
  /* Card Styles */
  .card {
    border: none;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  }
  
  .card-img-top {
    width: 100%; /* Ensure image covers full width of the card */
    height: 150px; /* Fixed height for images */
    object-fit: contain; /* Ensure image covers the area and maintains aspect ratio */
  }
  
  .card-body {
    padding: 1.25rem;
  }
  
  .card-title {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 10px;
    color: #0e6dda;
  }
  
  .card-text {
    font-size: 1rem;
    color: #666;
    line-height: 1.6;
  }
  
  .card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  /* Responsive Design for Cards */
  @media (max-width: 768px) {
    .card-img-top {
      height: auto; /* Maintain aspect ratio for smaller screens */
    }
  }
  
  
  
  
  





  