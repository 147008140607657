/* CSS for Header Section */
.node-header-image-container {
    position: relative;
    width: 100%;
    height: 70vh; /* Reduced height for a shorter image */
    overflow: hidden;
}

.node-header-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* Center the text and apply the background only to the text itself */
.node-text-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 20px;
    width: 100%;
}

/* Responsive overlay background and text */
.node-overlay-text {
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background around the text */
    color: #fff;
    padding: 20px;
    border-radius: 8px;
    max-width: 800px; /* Limit the width of the text */
    margin: auto; /* Ensure it stays centered */
}

.node-overlay-text h1 {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 20px;
}

.node-overlay-text p {
    font-size: 1.2rem;
    line-height: 1.5;
}

/* Responsive styles for text and layout */
@media (max-width: 1200px) {
    .node-overlay-text h1 {
        font-size: 2.2rem;
    }

    .node-overlay-text p {
        font-size: 1.1rem;
    }
}

@media (max-width: 992px) {
    .node-overlay-text h1 {
        font-size: 2rem;
    }

    .node-overlay-text p {
        font-size: 1rem;
    }
}

@media (max-width: 768px) {
    .node-overlay-text h1 {
        font-size: 1.8rem;
    }

    .node-overlay-text p {
        font-size: 0.95rem;
    }
}

@media (max-width: 576px) {
    .node-header-image-container {
        height: 50vh; /* Further reduce height for small screens */
    }

    .node-text-overlay {
        padding: 10px; /* Reduce padding for small screens */
    }

    .node-overlay-text {
        padding: 15px; /* Reduce padding for small screens */
    }

    .node-overlay-text h1 {
        font-size: 1.6rem;
    }

    .node-overlay-text p {
        font-size: 0.9rem;
    }
}




/* CSS for NodeJS Details Section */
.node-details-section {
    padding: 40px 20px;
    background-color: #f8f9fa; /* Light background for the details section */
  }
  
  .node-details-section h2 {
    font-size: 2rem;
    margin-bottom: 20px;
    text-align: center; /* Center the heading */
  }
  
  .nodejs-highlight {
    color: #0e6dda !important; /* Forcing the color */
    font-weight: bold; /* Optional */
  }
  .node-details-section p {
    font-size: 1.1rem;
    line-height: 1.6;
    margin-bottom: 40px; /* Space below the paragraph */
    text-align: center; /* Center the paragraph text */
  }
  
  .node-features {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center; /* Center align feature blocks */
  }
  
  .node-feature {
    flex: 1 1 30%; /* Responsive width */
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .node-feature-img {
    width: 60px; /* Adjust size as needed */
    height: auto;
    margin-bottom: 10px; /* Space below the image */
  }
  
  .node-feature h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .node-feature p {
    font-size: 1rem;
  }
  
  /* Responsive styles for features section */
  @media (max-width: 992px) {
    .node-feature {
      flex: 1 1 45%;
    }
  }
  
  @media (max-width: 768px) {
    .node-feature {
      flex: 1 1 100%;
    }
  }
  



 /* NodeJS Development Services Section */
 .node-development-services-section {
  padding: 50px 0;
  background-color: #f9f9f9;
}

/* Header Styling */
.node-development-services-section h2 {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 40px;
  color: #333;
}

.node-development-services-section h2 span {
  color: #007bff;
  font-weight: bold;
}

/* Service Wrapper */
.node-services {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

/* Individual Service Block */
.node-service {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.node-service:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
}

.node-service img {
  width: 10%; /* Adjust based on image size */
  border-radius: 10px;
  object-fit: cover;
}

.node-service-text {
  width: 55%; /* Text container width */
}

.node-service h3 {
  font-size: 1.8rem;
  margin-bottom: 15px;
  color: #007bff;
}

.node-service p {
  font-size: 1rem;
  color: #555;
  line-height: 1.8;
}

/* Responsive Design */
@media screen and (max-width: 1024px) {
  .node-service {
    flex-direction: column;
    text-align: center;
  }

  .node-service img {
    width: 80%; /* Adjust width as needed */
    margin-bottom: 20px;
  }

  .node-service-text {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .node-development-services-section h2 {
    font-size: 1.5rem; /* Slightly smaller font size for better fit */
  }

  .node-service img {
    width: 50%; /* Adjust width for smaller screens */
  }
}




  

  


 /* CSS for Why WWApps Section */
.node-why-wwapps-section {
    padding: 40px 20px;
    background-color: #f8f9fa;
  }
  
  .node-container {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .node-why-title {
    text-align: center; /* Center text horizontally */
    margin: 0 auto 30px; /* Center horizontally and add margin below */
    font-size: 2rem;
    color: #0e6dda;
    
    width: fit-content; /* Adjust width to content */
  }
  
  /* Other styles */
  .node-accordion-container {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap; /* Allow items to wrap on smaller screens */
  }
  
  .node-accordion-image {
    width: 300px; /* Adjust size as needed */
    height: auto;
    margin-right: 30px;
    max-width: 100%; /* Ensure the image scales down on smaller screens */
  }
  
  .node-accordion {
    flex: 1;
  }
  
  .node-accordion-item {
    border-bottom: 1px solid #ddd;
  }
  
  .node-accordion-header {
    cursor: pointer;
    padding: 15px;
    background-color: #fff;
    font-size: 1.2rem;
    font-weight: bold;
    border-bottom: 1px solid #ddd;
    user-select: none;
  }
  
  .node-accordion-item[open] .node-accordion-header {
    background-color: #e9ecef;
  }
  
  .node-accordion-item p {
    padding: 15px;
    margin: 0;
    background-color: #f8f9fa;
    border-top: 1px solid #ddd;
    overflow: hidden;
    max-height: 0; /* Hide content initially */
    transition: max-height 0.3s ease-out;
  }
  
  .node-accordion-item[open] p {
    max-height: 200px; /* Adjust based on content size */
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .node-accordion-container {
      flex-direction: column;
      align-items: center;
    }
  
    .node-accordion-image {
      width: 250px; /* Adjust size for smaller screens */
      margin-right: 0;
      margin-bottom: 20px;
    }
  }
  
  @media (max-width: 576px) {
    .node-accordion-image {
      width: 200px; /* Further adjust size for very small screens */
    }
  }
  
  