/* General Section Styling */
.home-info-section,
.home-mission-vision-section,
.home-certification-section,
.home-trusted-by-section {
    padding: 3rem 0; /* Padding for sections */
}

.home-info-section {
    background-color: #f8f9fa; /* Light background */
}

.home-mission-vision-section {
    background-color: #fff;
}

.home-certification-section {
    background-color: transparent; /* Transparent background */
    text-align: center;
}

.home-trusted-by-section {
    background-color: #0988ef; /* Background color */
    text-align: center; /* Center-align content */
}

/* Home Info Section */
.home-info-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #0e6dda;
}

.home-info-text {
    font-size: 1.125rem;
    color: #060606;
    font-weight: bold;
}

.home-info-btn {
    font-size: 1.2rem;
    padding: 0.75rem 0.75rem;
    background-color: #007bff;
    border: none;
    color: white;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.home-info-btn:hover {
    background-color: #0056b3;
}

.home-info-image {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
}

/* Mission and Vision Section */
.home-mission-vision-section {
    background: linear-gradient(135deg, rgba(0, 123, 255, 0.8), rgba(251, 251, 250, 0.8)); /* Gradient colors */
    padding: 2rem 0; /* Adjust padding if needed */
    position: relative; /* Ensure the section can have positioned child elements */
    overflow: hidden; /* Hide overflow to maintain clean edges */
}

.home-mission-content,
.home-vision-content {
    background: white; /* White background for content area */
    padding: 1.5rem;
    border-radius: 10px; /* Rounded corners for content area */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    transition: box-shadow 0.3s ease-in-out; /* Smooth shadow transition */
    position: relative; /* Ensure content sits above the overlay */
    z-index: 2; /* Ensure content is above the overlay */
}

.home-mission-content:hover,
.home-vision-content:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Larger shadow on hover */
}

.home-mission-image,
.home-vision-image {
    width: 100%; /* Full width of the container */
    height: 350px; /* Equal height for both images */
    object-fit: cover; /* Ensures the image covers the container without stretching */
    border-radius: 10px;
    margin-bottom: 1rem;
    position: relative;
    transition: transform 0.3s ease-in-out; /* Smooth transform transition */
}

.home-mission-image:hover,
.home-vision-image:hover {
    transform: scale(1.05); /* Slightly zoom the image on hover */
}

.home-mission-title,
.home-vision-title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1rem;
    color: #0e6dda;
}

.home-mission-text,
.home-vision-text {
    font-size: 1.125rem;
    color: #060606;
    margin-top: 1rem;
}






/* Certification Section */
.home-certification-section {
    background-color: #f5f5f5;
    padding: 3rem 0;
}

.home-certification-title {
    text-align: center;
    font-size: 2.5rem;
    font-weight: 600;
    margin-bottom: 2rem;
    color: #0e6dda;
}

.home-certification-container {
    position: relative;
    text-align: center;
}

.home-certification-images {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.row-1, .row-2, .row-3 {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
}

/* Adjust margins for pyramid spacing */
.row-1 .home-img-fluid {
    margin: 0 0.5rem;
}

.row-2 .home-img-fluid {
    margin: 0 1rem;
}

.row-3 .home-img-fluid {
    margin: 0 2rem;
}

.home-certification-images .home-img-fluid {
    width: 100%;
    max-width: 150px;
    height: auto;
    object-fit: contain;
    border-radius: 8px;
    transition: transform 0.3s ease;
    box-shadow: none; /* Ensure no shadow is applied */
}

.home-img-fluid:hover {
    transform: scale(1.05);
}

/* 'And Many More' styling */
.home-certification-more {
    text-align: center;
    margin-top: 3rem;
}

.home-certification-more span {
    font-size: 1.75rem;
    font-weight: 700;
    color: #2c3e50; /* Darker, richer color for emphasis */
    background: linear-gradient(90deg, #3498db, #8e44ad); /* Gradient background */
    -webkit-background-clip: text; /* Clip background to text */
    -webkit-text-fill-color: transparent; /* Make text fill transparent for gradient effect */
    padding: 0.5rem 1rem;
    border-radius: 8px;
    display: inline-block;
   
    text-transform: uppercase; /* Uppercase text for added emphasis */
    letter-spacing: 0.05rem; /* Slightly increased letter spacing */
    animation: glow 1.5s ease-in-out infinite;
}

/* Keyframes for glow animation */
@keyframes glow {
    0% {
        text-shadow: 0 0 5px rgba(0, 0, 0, 0.8);
    }
    50% {
        text-shadow: 0 0 20px rgba(0, 0, 0, 0.6);
    }
    100% {
        text-shadow: 0 0 5px rgba(0, 0, 0, 0.8);
    }
}






/* Responsive Styles */
@media (max-width: 768px) {
    .home-certification-images .home-img-fluid {
        max-width: 120px;
    }
}

@media (max-width: 480px) {
    .home-certification-images .home-img-fluid {
        max-width: 100px;
    }
}













/* Trusted By Section */
.home-trusted-by-section {
    background-color: #ffffff;
    padding: 3rem 0;
}

.home-trusted-by-title {
    font-size: 2.5rem;
    font-weight: 600;
    margin-bottom: 2rem;
    color: #0e6dda;
    text-align: center;
}

.home-trusted-images {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Three images per row */
    gap: 3rem;
    justify-content: center; /* Center items horizontally */
    align-items: center; /* Center items vertically */
}

/* Image Styling */
.home-trusted-img {
    width: 140px; /* Fixed width */
    height: 100px; /* Fixed height */
    object-fit: contain; /* Contain the image within the fixed size */
    border-radius: 8px;
    transition: transform 0.3s ease;
}

/* Hover Effect */
.home-trusted-img:hover {
    transform: scale(1.05); /* Slightly increased scale for effect */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}


/* Responsive Styles */
@media (max-width: 768px) {
    .home-trusted-images {
        grid-template-columns: repeat(3, minmax(80px, 1fr)); /* Maintain 3 columns for medium screens */
    }

    .home-trusted-img {
        width: 80px; /* Adjust width for smaller screens */
        height: 60px; /* Adjust height for smaller screens */
    }
}

@media (max-width: 480px) {
    .home-trusted-images {
        grid-template-columns: repeat(2, minmax(70px, 1fr)); /* 2 columns for smaller screens */
    }

    .home-trusted-img {
        width: 70px; /* Adjust width for very small screens */
        height: 50px; /* Adjust height for very small screens */
    }
}





















