/* Carousel Container for Navbar */
.navbar-carousel {
    position: relative;
    z-index: 1;
  }
  
  /* Ensure the carousel items are positioned correctly */
  .navbar-carousel .carousel-item {
    position: relative;
  }
  
  /* Overlay for carousel images */
  .navbar-carousel .carousel-item::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Blackish overlay with 50% opacity */
    z-index: 1; /* Place it above the images but below the captions */
  }
  
  /* Style for carousel images */
  .navbar-carousel .carousel-item img {
    object-fit: cover;
    height: 100vh;
    width: 100%;
  }
  
  /* Adjust the position of carousel captions */
  .navbar-carousel .carousel-caption {
    bottom: 20%;
    z-index: 2; /* Ensure captions are above the overlay */
  }
  
  /* Style for caption text */
  .navbar-carousel .carousel-caption h5,
  .navbar-carousel .carousel-caption h1 {
    color: #fff;
  }
  
  /* Style for buttons in captions */
  .navbar-carousel .carousel-caption .btn-primary {
    background: linear-gradient(135deg, #007bff, #0056b3);
  }
  
  /* Style for carousel control buttons */
  .navbar-carousel .carousel-control-prev,
  .navbar-carousel .carousel-control-next {
    width: 5%;
  }
  