/* Custom styles for overlay text */
.community-sites-header {
    position: relative;
  }
  
  .community-sites-img {
    object-fit: cover;
    height: 400px;
    width: 100%;
  }
  
  /* Flexbox container to center the overlay */
  .community-sites-overlay-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Ensure the overlay is centered and has a maximum width */
  .community-sites-overlay {
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 8px;
    max-width: 80%;
    padding: 20px;
    color: white;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
  }
  
  .community-sites-title {
    font-size: 3rem;
  }
  
  @media (max-width: 1200px) {
    .community-sites-img {
      height: 500px;
    }
    .community-sites-title {
      font-size: 2.5rem;
    }
  }
  
  @media (max-width: 768px) {
    .community-sites-img {
      height: 400px;
    }
    .community-sites-title {
      font-size: 2rem;
    }
    .community-sites-description {
      font-size: 1rem;
    }
  }
  
  @media (max-width: 576px) {
    .community-sites-img {
      height: 300px;
    }
    .community-sites-title {
      font-size: 1.75rem;
    }
    .community-sites-description {
      font-size: 0.9rem;
    }
    .community-sites-overlay {
      padding: 10px;
    }
  }




  /* Styles for the new content section */
.community-sites-content {
    background-color: #f9f9f9; /* Light background for contrast */
    padding: 3rem 0; /* Spacing above and below the section */
  }
  
  .community-sites-subtitle {
    font-size: 2.5rem;
    font-weight: 600;
    color: #0e6dda;
    margin-bottom: 1.5rem;
  }
  
  .community-sites-text {
    font-size: 1.2rem;
    color: #555;
    line-height: 1.6;
    margin-bottom: 1.5rem;
  }
  
  @media (max-width: 768px) {
    .community-sites-subtitle {
      font-size: 2rem;
    }
  
    .community-sites-text {
      font-size: 1rem;
    }
  }
  
  @media (max-width: 576px) {
    .community-sites-subtitle {
      font-size: 1.75rem;
    }
  
    .community-sites-text {
      font-size: 0.9rem;
    }
  }





  /* Why WWApps Section */
.community-sites-why-wwapps {
    background-color: #f8f9fa;
    padding: 3rem 0;
  }
  
  .community-sites-why-title {
    font-size: 2.5rem;
    font-weight: 600;
    color: #0e6dda;
    margin-bottom: 2rem;
  }
  
  .community-sites-why-text {
    font-size: 1.2rem;
    color: #555;
    line-height: 1.6;
    margin-bottom: 2rem;
  }
  
  /* Feature Icons and Text */
  .community-sites-icon-box {
    font-size: 3rem;
    color: #007bff;
    margin-bottom: 1rem;
  }
  
  .community-sites-feature-title {
    font-size: 1.5rem;
    font-weight: 600;
    color: #333;
    margin-bottom: 0.5rem;
  }
  
  .community-sites-feature-text {
    font-size: 1.1rem;
    color: #555;
    line-height: 1.6;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .community-sites-why-title {
      font-size: 2rem;
    }
  
    .community-sites-why-text {
      font-size: 1rem;
    }
  
    .community-sites-icon-box {
      font-size: 2.5rem;
    }
  
    .community-sites-feature-title {
      font-size: 1.25rem;
    }
  
    .community-sites-feature-text {
      font-size: 1rem;
    }
  }
  
  @media (max-width: 576px) {
    .community-sites-why-title {
      font-size: 1.75rem;
    }
  
    .community-sites-why-text {
      font-size: 0.9rem;
    }
  
    .community-sites-icon-box {
      font-size: 2rem;
    }
  
    .community-sites-feature-title {
      font-size: 1.1rem;
    }
  
    .community-sites-feature-text {
      font-size: 0.9rem;
    }
  }
  
  
  