/* Adjust the main content container on all pages */
body {
  padding-top: 70px; /* Adjust this value to match the height of your fixed navbar */
}

/* Header Section */
.community-cloud-header {
  position: relative;
  background: url('../Services pages/images/community.jpg') no-repeat center center/cover;
  height: 600px; /* Increased height for better visibility */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  padding: 40px;
  box-sizing: border-box;
  overflow: hidden;
}

.community-cloud-header-content {
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5);
  max-width: 1000px;
  width: 90%;
  box-sizing: border-box;
}

.community-cloud-header-content h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.community-cloud-header-content p {
  font-size: 1.2rem;
}

/* Main Section */
.community-cloud-main {
  width: 100%;
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.community-cloud-content-wrapper {
  text-align: center;
  margin-bottom: 40px;
}

.community-cloud-content-wrapper h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #0e6dda;
}

.community-cloud-content-wrapper p {
  font-size: 1.2rem;
  color: #0a0a0a;
  max-width: 1000px;
  margin: auto;
  font-weight: bold;
}

.community-cloud-cards-container,
.community-cloud-new-cards-container {
  display: grid;
  gap: 20px;
  width: 100%;
  max-width: 1200px;
  margin-bottom: 40px;
}

.community-cloud-cards-container {
  grid-template-columns: repeat(3, 1fr);
}

.community-cloud-second-row {
  grid-template-columns: repeat(2, 1fr);
}

.community-cloud-new-cards-container {
  grid-template-columns: repeat(3, 1fr);
}

.community-cloud-card,
.community-cloud-new-card,
.community-cloud-services-card {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.community-cloud-card:hover,
.community-cloud-new-card:hover,
.community-cloud-services-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 40px #0e6dda;
}

.community-cloud-card-icon,
.community-cloud-new-card-icon,
.community-cloud-services-card-icon {
  color: #0e6dda;
  margin-bottom: 20px;
}

.community-cloud-card h3,
.community-cloud-new-card h3,
.community-cloud-services-card h3 {
  font-size: 1.8rem;
  margin-bottom: 15px;
  color: #0e6dda;
}

.community-cloud-card p,
.community-cloud-new-card p,
.community-cloud-services-card p {
  font-size: 1rem;
  color: #666;
  margin-bottom: 10px;
}

/* New Section */
.community-cloud-new-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  background-color: #f0f4f8;
  margin-bottom: 40px;
}

.community-cloud-new-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  margin-bottom: 40px;
}

.community-cloud-new-text {
  flex: 1;
  padding-right: 20px;
}

.community-cloud-new-text h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #0e6dda;
}

.community-cloud-new-text p {
  font-size: 1.2rem;
  color: #555;
}

.community-cloud-new-image {
  flex: 1;
  text-align: right;
}

.community-cloud-new-image img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
}

/* Services Section */
.community-cloud-services {
  padding: 50px 20px;
  background: linear-gradient(135deg, #f9f9f9, #e2e2e2);
  color: #333;
}

.community-cloud-services-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
}

.community-cloud-services-text {
  text-align: center;
  margin-bottom: 20px;
}

.community-cloud-services-text h2 {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 15px;
  color: #0e6dda;
  transition: color 0.3s ease;
}

.community-cloud-services-text h2:hover {
  color: #007bff;
}

.community-cloud-services-text p {
  font-size: 1.125rem;
  line-height: 1.6;
  color: #555;
}

.community-cloud-services-image img {
  width: 100%;
  max-width: 600px;
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Responsive Adjustments */
@media (max-width: 1200px) {
  .community-cloud-header-content {
    width: 80%;
    padding: 20px;
  }

  .community-cloud-header-content h1 {
    font-size: 2rem;
  }

  .community-cloud-header-content p {
    font-size: 1rem;
  }

  .community-cloud-content-wrapper h2 {
    font-size: 2.2rem;
  }

  .community-cloud-content-wrapper p {
    font-size: 1.1rem;
  }

  .community-cloud-card h3,
  .community-cloud-new-card h3,
  .community-cloud-services-card h3 {
    font-size: 1.6rem;
  }

  .community-cloud-card p,
  .community-cloud-new-card p,
  .community-cloud-services-card p {
    font-size: 0.9rem;
  }

  .community-cloud-new-text h2 {
    font-size: 2.2rem;
  }

  .community-cloud-new-text p {
    font-size: 1rem;
  }

  .community-cloud-services-text h2 {
    font-size: 2.2rem;
  }

  .community-cloud-services-text p {
    font-size: 1rem;
  }
}

@media (max-width: 768px) {
  .community-cloud-header {
    height: 400px;
    padding: 20px;
  }

  .community-cloud-header-content {
    padding: 15px;
  }

  .community-cloud-header-content h1 {
    font-size: 1.8rem;
  }

  .community-cloud-header-content p {
    font-size: 0.9rem;
  }

  .community-cloud-content-wrapper h2 {
    font-size: 1.8rem;
  }

  .community-cloud-content-wrapper p {
    font-size: 1rem;
  }

  .community-cloud-card h3,
  .community-cloud-new-card h3,
  .community-cloud-services-card h3 {
    font-size: 1.4rem;
  }

  .community-cloud-card p,
  .community-cloud-new-card p,
  .community-cloud-services-card p {
    font-size: 0.8rem;
  }

  .community-cloud-new-text h2 {
    font-size: 1.8rem;
  }

  .community-cloud-new-text p {
    font-size: 0.9rem;
  }

  .community-cloud-services-text h2 {
    font-size: 1.8rem;
  }

  .community-cloud-services-text p {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .community-cloud-header {
    height: 300px;
    padding: 15px;
  }

  .community-cloud-header-content {
    padding: 10px;
  }

  .community-cloud-header-content h1 {
    font-size: 1.5rem;
  }

  .community-cloud-header-content p {
    font-size: 0.8rem;
  }

  .community-cloud-content-wrapper h2 {
    font-size: 1.6rem;
  }

  .community-cloud-content-wrapper p {
    font-size: 0.9rem;
  }

  .community-cloud-card h3,
  .community-cloud-new-card h3,
  .community-cloud-services-card h3 {
    font-size: 1.2rem;
  }

  .community-cloud-card p,
  .community-cloud-new-card p,
  .community-cloud-services-card p {
    font-size: 0.7rem;
  }

  .community-cloud-new-text h2 {
    font-size: 1.6rem;
  }

  .community-cloud-new-text p {
    font-size: 0.8rem;
  }

  .community-cloud-services-text h2 {
    font-size: 1.6rem;
  }

  .community-cloud-services-text p {
    font-size: 0.8rem;
  }
}
