.digital-marketing-page .digital-marketing-header-section {
    position: relative;
    overflow: hidden; /* Ensure no overflow outside the container */
  }
  
  .digital-marketing-page .digital-marketing-header-image {
    width: 100%;
    height: 400px;
    object-fit: cover;
  }
  
  .digital-marketing-page .digital-marketing-overlay-text {
    position: absolute;
    top: 50%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Center the text block */
    background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent background */
    color: white;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    width: 80%; /* Ensure the text box doesn’t overflow */
    box-sizing: border-box; /* Include padding in width calculation */
  }
  
  .digital-marketing-page .digital-marketing-overlay-text h1 {
    font-size: 3rem;
    font-weight: bold;
    margin: 0; /* Remove default margin */
  }
  
  .digital-marketing-page .digital-marketing-overlay-text p {
    font-size: 1.5rem;
    margin: 10px 0 0; /* Remove default margin */
  }
  
  /* Media Queries for Responsiveness */
  @media (max-width: 768px) {
    .digital-marketing-page .digital-marketing-header-image {
      height: 350px; /* Reduce height for smaller screens */
    }
  
    .digital-marketing-page .digital-marketing-overlay-text {
      padding: 15px;
      width: 90%; /* Ensure the text box stays within the viewport */
    }
  
    .digital-marketing-page .digital-marketing-overlay-text h1 {
      font-size: 2rem; /* Adjust font size for tablets */
    }
  
    .digital-marketing-page .digital-marketing-overlay-text p {
      font-size: 1.2rem; /* Adjust font size for tablets */
    }
  }
  
  @media (max-width: 576px) {
    .digital-marketing-page .digital-marketing-header-image {
      height: 250px; /* Reduce height further for mobile devices */
    }
  
    .digital-marketing-page .digital-marketing-overlay-text {
      padding: 10px;
      width: 95%; /* Keep text within the viewport on mobile */
    }
  
    .digital-marketing-page .digital-marketing-overlay-text h1 {
      font-size: 1.8rem; /* Adjust font size for mobile */
    }
  
    .digital-marketing-page .digital-marketing-overlay-text p {
      font-size: 1rem; /* Adjust font size for mobile */
    }
  }





 /* info Section Styles */
 .digital-marketing-page .digital-marketing-info-section {
    padding: 60px 30px;
    text-align: center;
    background: linear-gradient(to right, #f9f9f9 50%, #e0e0e0 100%);
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: relative;
    overflow: hidden;
  }
  
  .digital-marketing-page .digital-marketing-info-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.05);
    z-index: 1;
  }
  
  .digital-marketing-page .digital-marketing-info-section h2 {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
    position: relative;
    z-index: 2;
  }
  
  .digital-marketing-page .digital-marketing-info-section p {
    font-size: 1.2rem;
    line-height: 1.8;
    margin: 10px 0;
    color: #555;
    position: relative;
    z-index: 2;
  }
  
  /* Highlighted Text Styling */
  .digital-marketing-page .highlighted-text {
    color: #0e6dda; /* Change this to the desired color */
    font-weight: bold;
  }
  
  /* Hover Effects */
  .digital-marketing-page .digital-marketing-info-section:hover {
    background: linear-gradient(to right, #e0e0e0 50%, #f9f9f9 100%);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    transform: translateY(-5px);
    transition: all 0.3s ease;
  }
  
  /* Media Queries for Responsiveness */
  @media (max-width: 768px) {
    .digital-marketing-page .digital-marketing-info-section {
      padding: 40px 20px;
    }
  
    .digital-marketing-page .digital-marketing-info-section h2 {
      font-size: 2.5rem;
    }
  
    .digital-marketing-page .digital-marketing-info-section p {
      font-size: 1rem;
    }
  }
  
  @media (max-width: 576px) {
    .digital-marketing-page .digital-marketing-info-section {
      padding: 30px 15px;
    }
  
    .digital-marketing-page .digital-marketing-info-section h2 {
      font-size: 2rem;
    }
  
    .digital-marketing-page .digital-marketing-info-section p {
      font-size: 0.9rem;
    }
  }






  .digital-marketing-page .digital-marketing-services-section {
    padding: 80px 40px;
    background: linear-gradient(135deg, #f5f7fa, #c3cfe2); /* Soft gradient background */
    text-align: center;
  }
  
  .digital-marketing-page .digital-marketing-services-section h2 {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 40px;
    color: #0e6dda; /* Elegant color */
  }
  
  .digital-marketing-page .digital-marketing-services-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px; /* Increased gap for better spacing */
  }
  
  .digital-marketing-page .digital-marketing-service-item {
    background: #ffffff;
    padding: 30px; /* Increased padding for a more spacious feel */
    border-radius: 15px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1); /* Softer shadow */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    max-width: 320px; /* Slightly larger width for better visibility */
    position: relative;
  }
  
  .digital-marketing-page .digital-marketing-service-item::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 6px; /* Slightly thicker accent */
    height: 100%;
    background: #007bff;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    transition: background-color 0.3s ease;
  }
  
  .digital-marketing-page .digital-marketing-service-item:hover {
    transform: translateY(-10px); /* Slightly increased hover effect */
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15);
  }
  
  .digital-marketing-page .digital-marketing-service-item:hover::before {
    background-color: #0056b3;
  }
  
  .digital-marketing-page .digital-marketing-service-item h3 {
    font-size: 1.8rem; /* Slightly larger font size */
    margin-bottom: 20px;
    color: #34495e; /* Modern, clean color */
    font-weight: 600;
  }
  
  .digital-marketing-page .digital-marketing-service-item p {
    font-size: 1.2rem; /* Increased font size for better readability */
    color: #7f8c8d; /* Softer text color */
    line-height: 1.8;
  }
  
  /* Media Queries for Responsiveness */
  @media (max-width: 992px) {
    .digital-marketing-page .digital-marketing-services-section {
      padding: 60px 30px;
    }
  
    .digital-marketing-page .digital-marketing-services-section h2 {
      font-size: 2rem;
    }
  }
  
  @media (max-width: 768px) {
    .digital-marketing-page .digital-marketing-services-section {
      padding: 50px 20px;
    }
  
    .digital-marketing-page .digital-marketing-services-list {
      gap: 30px; /* Adjusted gap for medium devices */
    }
  
    .digital-marketing-page .digital-marketing-service-item {
      padding: 20px;
      max-width: 90%;
    }
  }
  
  @media (max-width: 576px) {
    .digital-marketing-page .digital-marketing-services-section {
      padding: 40px 15px;
    }
  
    .digital-marketing-page .digital-marketing-services-section h2 {
      font-size: 1.8rem;
    }
  
    .digital-marketing-page .digital-marketing-service-item {
      padding: 15px;
      max-width: 100%;
    }
  }
  
  




  .digital-marketing-page .digital-marketing-methods-section {
    padding: 80px 40px;
    background: linear-gradient(135deg, #f5f7fa, #c3cfe2); /* Soft gradient background */
    text-align: center;
  }
  
  .digital-marketing-page .digital-marketing-methods-section h2 {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 40px;
    color: #0e6dda; /* Softer, more elegant color */
  }
  
  .digital-marketing-page .digital-marketing-methods-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px; /* Increased spacing for a more breathable layout */
  }
  
  .digital-marketing-page .digital-marketing-method-item {
    background: #ffffff;
    padding: 30px;
    border-radius: 15px;
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1); /* Softer shadow */
    max-width: 320px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .digital-marketing-page .digital-marketing-method-item:hover {
    transform: translateY(-10px); /* Slight hover effect */
    box-shadow: 0 12px 30px rgba(0, 0, 0, 0.15);
  }
  
  .digital-marketing-page .digital-marketing-method-item h3 {
    font-size: 1.8rem;
    margin-bottom: 20px;
    color: #34495e; /* Elegant and modern */
    font-weight: 600;
  }
  
  .digital-marketing-page .digital-marketing-method-item p {
    font-size: 1.2rem;
    color: #7f8c8d; /* Muted text for sophistication */
    line-height: 1.8;
  }
  
  .digital-marketing-page .method-icon {
    font-size: 3rem;
    color: #2980b9; /* Calm, professional tone */
    margin-bottom: 20px;
  }
  
  .digital-marketing-page .method-icon i {
    transition: color 0.3s ease;
  }
  
  .digital-marketing-page .digital-marketing-method-item:hover .method-icon i {
    color: #1abc9c; /* Gentle hover color change */
  }
  
  /* Media Queries for Responsiveness */
  @media (max-width: 992px) {
    .digital-marketing-page .digital-marketing-methods-section {
      padding: 60px 30px;
    }
  
    .digital-marketing-page .digital-marketing-methods-section h2 {
      font-size: 2.2rem;
    }
  
    .digital-marketing-page .digital-marketing-methods-list {
      gap: 30px; /* Adjusted gap for medium devices */
    }
  
    .digital-marketing-page .digital-marketing-method-item {
      padding: 25px;
      max-width: 280px;
    }
  }
  
  @media (max-width: 768px) {
    .digital-marketing-page .digital-marketing-methods-section {
      padding: 50px 20px;
    }
  
    .digital-marketing-page .digital-marketing-methods-section h2 {
      font-size: 2rem;
    }
  
    .digital-marketing-page .digital-marketing-methods-list {
      gap: 20px;
    }
  
    .digital-marketing-page .digital-marketing-method-item {
      padding: 20px;
      max-width: 90%;
    }
  }
  
  @media (max-width: 576px) {
    .digital-marketing-page .digital-marketing-methods-section {
      padding: 40px 15px;
    }
  
    .digital-marketing-page .digital-marketing-methods-section h2 {
      font-size: 1.8rem;
    }
  
    .digital-marketing-page .digital-marketing-methods-list {
      gap: 15px;
    }
  
    .digital-marketing-page .digital-marketing-method-item {
      padding: 15px;
      max-width: 100%;
    }
  
    .digital-marketing-page .method-icon {
      font-size: 2.5rem;
    }
  
    .digital-marketing-page .digital-marketing-method-item h3 {
      font-size: 1.6rem;
    }
  
    .digital-marketing-page .digital-marketing-method-item p {
      font-size: 1.1rem;
    }
  }
  