@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&family=Montserrat:wght@400;600&display=swap');

.about-container {
    width: 100%;
    height: auto;
}

/* About Banner Section */
/* About Banner Section */
.about-banner {
    position: relative;
    text-align: center;
    color: white; /* Text color, adjust as needed */
    overflow: hidden; /* Ensures no content overflows the container */
  }
  
  .about-banner-image {
    width: 100%;
    height: 60vh; /* Adjust the height as needed */
    object-fit: cover; /* Ensures the image covers the area without distortion */
    display: block;
  }
  
  .about-banner-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.5); /* Overlay color with opacity */
    padding: 15px; /* Adjust padding as needed */
    border-radius: 8px; /* Optional: Rounded corners for the text box */
    max-width: 80%; /* Adjust to ensure it fits within the image */
    box-sizing: border-box; /* Ensure padding does not affect width */
    font-size: 1.2em; /* Adjust font size */
    
    white-space: normal; /* Ensure text wraps within the container */
    line-height: 1.4; /* Adjust line height for better readability */
    display: flex; /* Use flexbox for better centering */
    align-items: center; /* Center text vertically */
    justify-content: center; /* Center text horizontally */
    text-align: center; /* Center align text inside the overlay */
    max-height: 80%; /* Ensure text container height is limited */
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .about-banner-text {
      font-size: 1em; /* Adjust font size for smaller screens */
      padding: 10px;
      max-width: 90%; /* Increase width for smaller screens */
      max-height: 70%; /* Reduce max-height for smaller screens */
    }
  
    .about-banner-image {
      height: 50vh; /* Adjust height for smaller screens */
    }
  }
  
  @media (max-width: 480px) {
    .about-banner-text {
      font-size: 0.9em; /* Further adjust font size for very small screens */
      padding: 8px;
      max-width: 95%; /* Increase width for very small screens */
      max-height: 60%; /* Further reduce max-height for very small screens */
    }
  
    .about-banner-image {
      height: 40vh; /* Adjust height for very small screens */
    }
  }
  
  
  
  
  
  






/* About Section Styles */
.about-section {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    padding: 3rem;
    gap: 2rem;
    background: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    animation: fadeInSection 1s ease-out;
}

.about-image-container {
    flex: 1;
    max-width: 100%;
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    transition: transform 0.5s ease;
}

.about-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.5s ease;
}

.about-image-container:hover .about-image {
    transform: scale(1.05);
}

.about-text-container {
    flex: 1;
    padding: 2rem;
    max-width: 100%;
}

.about-text-container h2 {
    font-family: 'Montserrat', sans-serif;
    font-size: 2.5rem;
    color: #2c3e50;
    margin-bottom: 1rem;
    position: relative;
    display: inline-block;
}

.about-text-container h2::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -10px;
    width: 50px;
    height: 4px;
    background: #0e6dda;
    border-radius: 2px;
    transition: width 0.3s ease;
}

.about-text-container h2:hover::before {
    width: 100%;
}

.about-text-container p {
    font-family: 'Roboto', sans-serif;
    font-size: 1.2rem;
    color: #555;
    line-height: 1.8;
    text-align: justify;
    position: relative;
    padding-bottom: 1rem;
}

.about-text-container p::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background: #0e6dda;
    border-radius: 2px;
    transition: width 0.3s ease;
}

.about-text-container p:hover::after {
    width: 100%;
}

@media (max-width: 1024px) {
    .about-section {
        padding: 2rem;
        flex-direction: column;
    }

    .about-image-container {
        margin-bottom: 1.5rem;
    }
}

@media (max-width: 768px) {
    .about-text-container h2 {
        font-size: 2rem;
    }
}

@media (max-width: 480px) {
    .about-text-container h2 {
        font-size: 1.5rem;
    }
}

/* Values Section Styles */
.values-section {
    padding: 4rem 2rem;
    background: linear-gradient(to right, #f9f9f9, #f1f1f1);
    border-top: 5px solid #3498db;
    border-bottom: 5px solid #3498db;
    position: relative;
    overflow: hidden;
}

.values-section::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    z-index: 0;
}

.values-section h2 {
    font-family: 'Montserrat', sans-serif;
    font-size: 2.5rem;
    color: #2c3e50;
    margin-bottom: 2rem;
    text-align: center;
    position: relative;
    z-index: 1;
}

.values-section h2::after {
    content: "";
    position: absolute;
    left: 50%;
    bottom: -15px;
    transform: translateX(-50%);
    width: 80px;
    height: 6px;
    background: #3498db;
    border-radius: 3px;
}

.values-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: center;
    z-index: 1;
    position: relative;
}

.value-card {
    flex: 1 1 calc(33.333% - 2rem);
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    transition: transform 0.4s ease, box-shadow 0.4s ease, background 0.4s ease, border 0.4s ease;
    position: relative;
    overflow: hidden;
    border: 2px solid transparent;
}

.value-card::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background: #3498db;
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.4s ease;
}

.value-card:hover::before {
    transform: scaleX(1);
}

.value-card:hover {
    transform: translateY(-15px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
    background: linear-gradient(145deg, #f0f8ff, #e0e0e0);
    border-color: #3498db;
}

.value-card h3 {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.8rem;
    color: #3498db;
    margin-bottom: 1rem;
    position: relative;
}

.value-card p {
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    color: #555;
    line-height: 1.6;
}

.value-card .card-icon {
    font-size: 3rem;
    color: #3498db;
    margin-bottom: 1rem;
    transition: color 0.4s ease;
}

.value-card:hover .card-icon {
    color: #2c3e50;
}

@media (max-width: 1024px) {
    .values-cards {
        flex-direction: column;
        align-items: center;
    }

    .value-card {
        flex: 1 1 100%;
    }
}

@media (max-width: 768px) {
    .values-section h2 {
        font-size: 2rem;
    }

    .value-card {
        padding: 1.5rem;
    }
}

@media (max-width: 480px) {
    .values-section h2 {
        font-size: 1.5rem;
    }

    .value-card {
        padding: 1rem;
    }
}

/* Keyframes */
@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(200px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeInSection {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
