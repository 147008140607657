.app-development-header-section {
  position: relative;
  text-align: center;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70vh; /* Make the section full viewport height */
  overflow: hidden; /* Hide overflow to keep the design clean */
}

.app-development-header-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.app-development-overlay-text {
  position: absolute;
  max-width: 90%;
  padding: 20px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  box-sizing: border-box;
  text-align: center;
}

.app-development-overlay-text h1 {
  font-size: 2.5rem;
  margin-bottom: 10px;
}

.app-development-overlay-text p {
  font-size: 1.125rem;
  line-height: 1.6;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .app-development-overlay-text h1 {
    font-size: 2rem;
  }

  .app-development-overlay-text p {
    font-size: 1rem;
  }

  .app-development-overlay-text {
    padding: 15px;
  }
}

@media (max-width: 480px) {
  .app-development-overlay-text h1 {
    font-size: 1.5rem;
  }

  .app-development-overlay-text p {
    font-size: 0.875rem;
  }

  .app-development-overlay-text {
    padding: 10px;
  }
}




  



  /* Salesforce development services */
.app-development-page .app-development-info-section {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 40px;
    background: #f9f9f9; /* Light background for the new section */
  }
  
  .app-development-page .app-development-text {
    flex: 1;
    max-width: 600px;
    padding: 20px;
  }
  
  .app-development-page .app-development-text h2 {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #0e6dda;
  }
  
  .app-development-page .app-development-text h3 {
    font-size: 1.5rem;
    margin-top: 20px;
    color: #0e6dda;
  }
  
  .app-development-page .app-development-text p {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 15px;
  }
  
  .app-development-page .app-development-image {
    flex: 1;
    max-width: 600px;
    padding: 20px;
  }
  
  .app-development-page .app-development-image img {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }
  
  /* Responsive adjustments */
  @media (max-width: 992px) {
    .app-development-page .app-development-info-section {
      flex-direction: column;
      text-align: center;
    }
  
    .app-development-page .app-development-text,
    .app-development-page .app-development-image {
      max-width: 100%;
    }
  
    .app-development-page .app-development-text h2 {
      font-size: 1.75rem;
    }
  
    .app-development-page .app-development-text p {
      font-size: 0.9rem;
    }
  }
  
  @media (max-width: 768px) {
    .app-development-page .app-development-text h2 {
      font-size: 1.5rem;
    }
  
    .app-development-page .app-development-text p {
      font-size: 0.85rem;
    }
  }
  
  @media (max-width: 576px) {
    .app-development-page .app-development-text h2 {
      font-size: 1.25rem;
    }
  
    .app-development-page .app-development-text p {
      font-size: 0.8rem;
    }
  }
  



  /* New Section Styles */
.app-development-services-section {
    padding: 40px;
    background-color: #f8f9fa; /* Light background color */
  }
  
  .app-development-services-section h2 {
    font-size: 2rem;
    margin-bottom: 20px;
    text-align: center;
    color: #0e6dda;
  }
  
  .app-development-services-section p {
    font-size: 1rem;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .service-card {
    display: flex;
    align-items: center;
    background: #fff; /* White background for each card */
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1); /* Subtle shadow */
    margin-bottom: 20px;
    padding: 15px;
  }
  
  .service-card-image {
    width: 80px;
    height: 80px;
    margin-right: 20px;
    object-fit: cover;
    border-radius: 50%;
  }
  
  .service-card-content {
    flex: 1;
  }
  
  .service-card-content h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .service-card-content p {
    font-size: 1rem;
    line-height: 1.6;
  }
  
  /* Responsive adjustments */
  @media (max-width: 992px) {
    .app-development-services-section {
      padding: 20px;
    }
  
    .app-development-services-section h2 {
      font-size: 1.75rem;
    }
  
    .app-development-services-section p {
      font-size: 0.9rem;
    }
  
    .service-card {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .service-card-image {
      margin-right: 0;
      margin-bottom: 10px;
    }
  
    .service-card-content h3 {
      font-size: 1.4rem;
    }
  
    .service-card-content p {
      font-size: 0.9rem;
    }
  }
  
  @media (max-width: 768px) {
    .app-development-services-section {
      padding: 15px;
    }
  
    .app-development-services-section h2 {
      font-size: 1.5rem;
    }
  
    .app-development-services-section p {
      font-size: 0.85rem;
    }
  
    .service-card-content h3 {
      font-size: 1.2rem;
    }
  
    .service-card-content p {
      font-size: 0.85rem;
    }
  }
  
  @media (max-width: 576px) {
    .app-development-services-section {
      padding: 10px;
    }
  
    .app-development-services-section h2 {
      font-size: 1.25rem;
    }
  
    .app-development-services-section p {
      font-size: 0.8rem;
    }
  
    .service-card {
      padding: 10px;
    }
  
    .service-card-content h3 {
      font-size: 1rem;
    }
  
    .service-card-content p {
      font-size: 0.8rem;
    }
  }
  
  


  /* App_Development.css */

/* Why WWApps Section */
.app-development-why-cloud-analogy-section {
    padding: 50px 20px;
    background-color: #ffffff;
    border-top: 2px solid #0070d2;
  }
  
  .app-development-why-cloud-analogy-section h2 {
    text-align: center;
    margin-bottom: 30px;
    font-size: 2.5rem;
    color: #0070d2;
  }
  
  .app-development-why-cloud-analogy-content {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .app-development-why-cloud-analogy-content p {
    font-size: 1.1rem;
    line-height: 1.6;
    color: #333;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .app-development-why-cloud-analogy-items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
  }
  
  .why-cloud-analogy-item {
    background: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 300px;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    padding: 20px;
  }
  
  .why-cloud-analogy-item:hover {
    transform: translateY(-8px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .why-cloud-analogy-item-image {
    width: 80px;
    height: 80px;
    margin: 0 auto;
    display: block;
    object-fit: contain;
  }
  
  .why-cloud-analogy-item h3 {
    font-size: 1.5rem;
    margin-top: 15px;
    color: #0070d2;
  }
  
  .why-cloud-analogy-item p {
    font-size: 1rem;
    color: #666;
    margin-top: 10px;
    line-height: 1.4;
  }
  
  /* Responsive Styles */
  @media (max-width: 1200px) {
    .app-development-why-cloud-analogy-section h2 {
      font-size: 2rem;
    }
  
    .app-development-why-cloud-analogy-content p {
      font-size: 1rem;
    }
  
    .app-development-why-cloud-analogy-items {
      gap: 20px;
    }
  }
  
  @media (max-width: 992px) {
    .app-development-why-cloud-analogy-items {
      flex-direction: column;
      align-items: center;
    }
  
    .why-cloud-analogy-item {
      max-width: 100%;
      padding: 15px;
    }
  
    .why-cloud-analogy-item-image {
      width: 70px;
      height: 70px;
    }
  
    .why-cloud-analogy-item h3 {
      font-size: 1.25rem;
    }
  
    .why-cloud-analogy-item p {
      font-size: 0.9rem;
    }
  }
  
  @media (max-width: 768px) {
    .app-development-why-cloud-analogy-section {
      padding: 40px 15px;
    }
  
    .app-development-why-cloud-analogy-content p {
      font-size: 0.9rem;
    }
  
    .why-cloud-analogy-item {
      max-width: 100%;
      padding: 10px;
    }
  
    .why-cloud-analogy-item-image {
      width: 60px;
      height: 60px;
    }
  
    .why-cloud-analogy-item h3 {
      font-size: 1.1rem;
    }
  
    .why-cloud-analogy-item p {
      font-size: 0.85rem;
    }
  }
  
  @media (max-width: 576px) {
    .app-development-why-cloud-analogy-section {
      padding: 30px 10px;
    }
  
    .app-development-why-cloud-analogy-content p {
      font-size: 0.85rem;
    }
  
    .why-cloud-analogy-item {
      max-width: 100%;
      padding: 10px;
    }
  
    .why-cloud-analogy-item-image {
      width: 50px;
      height: 50px;
    }
  
    .why-cloud-analogy-item h3 {
      font-size: 1rem;
    }
  
    .why-cloud-analogy-item p {
      font-size: 0.8rem;
    }
  }
  