/* Adjust the main content container on all pages */
body {
  padding-top: 70px; /* Adjust this value to match the height of your fixed navbar */
}

/* Ensure the section with the image is positioned relatively for overlay positioning */
section {
  position: relative;
  overflow: hidden; /* Hide overflow to prevent scrollbars if image is too large */
}

/* Wrapper to control image height */
.image-wrapper {
  height: 400px; /* Adjust the height as needed */
  overflow: hidden; /* Hide any overflow from the image */
}

/* Style for the image to ensure it's responsive within the wrapper */
.image-wrapper img {
  width: 100%; /* Make sure the image takes up full width of its container */
  height: 100%; /* Make the image height match the wrapper height */
  object-fit: cover; /* Ensure the image covers the wrapper properly */
}

/* Styling for the overlay text to ensure it's centered and readable */
.overlay-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Center the text */
  text-align: center;
  color: white;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background for readability */
  width: 80%; /* Adjust width as needed */
  max-width: 1200px; /* Set a max-width for larger screens */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); /* Optional: add shadow for better visibility */
}

/* Responsive styling for text */
.overlay-text h1 {
  font-size: 2.5rem; /* Adjust font size for larger screens */
  font-weight: bold;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.7); /* Add shadow for better text visibility */
}

.overlay-text p {
  font-size: 1.2rem; /* Adjust font size */
  line-height: 1.6;
  margin-top: 15px;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.7); /* Add shadow for better text visibility */
}

/* Responsive adjustments */
@media (max-width: 992px) {
  .overlay-text h1 {
    font-size: 2rem;
  }

  .overlay-text p {
    font-size: 0.9rem;
  }
}

@media (max-width: 768px) {
  .overlay-text h1 {
    font-size: 1.75rem;
  }

  .overlay-text p {
    font-size: 0.85rem;
  }
}

@media (max-width: 576px) {
  .overlay-text h1 {
    font-size: 1.5rem;
  }

  .overlay-text p {
    font-size: 0.8rem;
  }
}

/* Tools Section */
.tools-section {
  background-color: #f8f9fa;
  padding: 4rem 2rem; /* Adjust padding for smaller screens */
}

.tools-section h2 {
  font-size: 2.5rem;
  color: #0e6dda;
  margin-bottom: 1.5rem;
}

.tools-section p.lead {
  font-size: 1.2rem;
  color: #050505;
  margin-bottom: 2rem;
  font-weight: bold;
}

.tools-section .card {
  border: 1px solid #0e6dda; /* Add border to see if it's applied */
  background-color: white;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border-radius: 10px;
}

.tools-section .card:hover {
  border: 1px solid rgb(7, 7, 7); /* Change border color on hover to test */
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(14, 109, 218, 0.5);
}

.tools-section .card i {
  color: #007bff;
  transition: color 0.3s;
}

.tools-section .card:hover i {
  color: #0056b3;
}

.tools-section .card-title {
  font-size: 1.25rem;
  color: #333;
  font-weight: 600;
  margin-bottom: 1rem;
}

.tools-section .card-text {
  font-size: 1rem;
  color: #666;
}

@media (max-width: 992px) {
  .tools-section h2 {
    font-size: 2rem;
  }

  .tools-section .card-title {
    font-size: 1.125rem;
  }

  .tools-section .card-text {
    font-size: 0.9rem;
  }
}

/* Why Cloud Analogy Section */
.why-cloud-analogy {
  background-color: #f8f9fa;
  padding: 4rem 2rem; /* Adjust padding for smaller screens */
}

.why-cloud-analogy h2 {
  font-size: 2.5rem;
  color: #0e6dda;
}

.why-cloud-analogy p.lead {
  font-size: 1.125rem;
  color: #060606;
  font-weight: bold;
}

.why-cloud-analogy h4 {
  font-size: 1.75rem;
  color: #0e6dda;
  margin-bottom: 1rem;
}

.why-cloud-analogy p {
  font-size: 1rem;
  color: #333;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .why-cloud-analogy h2 {
    font-size: 2rem;
  }

  .why-cloud-analogy h4 {
    font-size: 1.5rem;
  }

  .why-cloud-analogy p {
    font-size: 0.9rem;
  }
}
