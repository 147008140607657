/* Import custom fonts */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&family=Montserrat:wght@400;600&display=swap');

/* General Styles */
.ourteam-container {
  font-family: 'Roboto', sans-serif;
  color: #333;
  padding: 2rem;
  background-color: #f8f9fa;
  position: relative;
  overflow: hidden;
}

/* Background Animation */
.ourteam-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(45deg, rgba(52,152,219,0.15), rgba(41,128,185,0.15));
  z-index: -1;
  transform: translate(-50%, -50%) rotate(0deg);
  transform-origin: center;
  animation: rotateBg 20s linear infinite;
}

@keyframes rotateBg {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

/* Header Section */
.ourteam-header-container {
  text-align: center;
  margin-bottom: 2rem;
  position: relative;
}

.ourteam-header-title {
  font-family: 'Montserrat', sans-serif;
  font-size: 3rem;
  color: #2c3e50;
  margin: 0;
  padding: 0;
  animation: fadeIn 1.5s ease-in-out;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}

.ourteam-header-image {
  width: 100%;
  height: auto;
  max-height: 400px;
  object-fit: cover;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, filter 0.3s ease, box-shadow 0.3s ease;
}

.ourteam-header-image:hover {
  transform: scale(1.05);
  filter: brightness(90%);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Main Sections */
.ourteam-main-heading-container {
  text-align: center;
  margin-bottom: 2rem;
  position: relative;
  overflow: hidden;
}

.ourteam-main-heading-title {
  font-family: 'Montserrat', sans-serif;
  font-size: 2.5rem;
  color: #2c3e50;
  margin: 0;
  padding: 0;
  animation: fadeIn 1s ease-in-out, bounceText 3s ease-in-out infinite alternate;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}

@keyframes bounceText {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}

.ourteam-introduction-section, .ourteam-mission-section {
  margin-bottom: 2rem;
  animation: fadeInUp 1s ease-out;
  position: relative;
  background: rgba(255, 255, 255, 0.95);
  padding: 1rem;
  border-radius: 15px;
  box-shadow: 0 8px 16px #0c0c0c75;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.ourteam-introduction-section:hover, .ourteam-mission-section:hover {
  transform: scale(1.02);
  box-shadow: 0 12px 24px #0e6dda;
}

.ourteam-subheading {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.75rem;
  color: #0e6dda;
  margin-bottom: 1rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-bottom: 2px solid #0e6dda;
  padding-bottom: 0.5rem;
  position: relative;
}

.ourteam-subheading::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: #0e6dda;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.4s ease;
}

.ourteam-subheading:hover::before {
  transform: scaleX(1);
}

.ourteam-introduction, .ourteam-mission {
  font-size: 1.1rem;
  color: #555;
  line-height: 1.6;
  border-left: 5px solid #0e6dda;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.85);
  padding: 1rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: background 0.3s ease, box-shadow 0.3s ease;
}

.ourteam-introduction:hover, .ourteam-mission:hover {
  background: rgba(255, 255, 255, 1);
  box-shadow: 0 6px 12px #0e6dda;
}

.ourteam-thankyou-text {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.4rem;
  color: #2c3e50;
  font-weight: 700;
  animation: fadeIn 1s ease-in-out;
  text-align: center;
}

/* Content Cards */
.ourteam-content-container {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: center;
  position: relative;
}

/* Member Info */
.ourteam-content-card {
  background-color: #ffffff;
  background-image: 
    linear-gradient(45deg, rgba(255, 255, 255, 0.5) 25%, transparent 25%), 
    linear-gradient(-45deg, rgba(255, 255, 255, 0.5) 25%, transparent 25%), 
    linear-gradient(45deg, transparent 75%, rgba(200, 200, 200, 0.2) 75%), 
    linear-gradient(-45deg, transparent 75%, rgba(200, 200, 200, 0.2) 75%);
  background-size: 20px 20px;
  background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.ourteam-content-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 16px #0e6dda;
}




.ourteam-card-title {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.8rem;
  color: #0e6dda;
  margin-bottom: 0.5rem;
  text-align: center;
  font-weight: bold;
}

.ourteam-card-text {
  font-family: 'Roboto', sans-serif;
  font-size: 1.2rem;
  color: #040404;
  margin-bottom: 0.5rem;
  text-align: center;
}

.ourteam-card-bio {
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  color: #777;
  text-align: center;
  margin-bottom: 1rem;
}

/* Social Links */
.ourteam-social-links {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  opacity: 0;
  transition: opacity 0.4s ease;
}

.ourteam-content-card:hover .ourteam-social-links {
  opacity: 1;
}

.ourteam-social-links a {
  color: #3498db;
  margin: 0 0.5rem;
  font-size: 1.4rem;
  transition: color 0.4s ease, transform 0.4s ease, box-shadow 0.4s ease;
}

.ourteam-social-links a:hover {
  color: #2980b9;
  transform: scale(1.2) rotate(10deg);
  box-shadow: 0 0 8px rgba(41, 128, 185, 0.5);
}
