/* Angular Page Styles */
.angular-header {
    position: relative;
    height: 400px;
    overflow: hidden; /* Prevent overflow */
  }
  
  .angular-img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
  
  .angular-overlay-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .angular-overlay {
    max-width: 80%; /* Ensures the overlay stays within the image bounds */
    background-color: rgba(0, 0, 0, 0.75);
    padding: 2rem;
    border-radius: 0.5rem;
    text-align: center;
  }
  
  .angular-title {
    color: #fff;
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }
  
  .angular-description {
    color: #fff;
    font-size: 1.2rem;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .angular-title {
      font-size: 2rem;
    }
  
    .angular-description {
      font-size: 1rem;
    }
  
    .angular-header {
      height: 300px;
    }
  
    .angular-overlay {
      max-width: 90%;
      padding: 1.5rem;
    }
  }
  
  @media (max-width: 576px) {
    .angular-title {
      font-size: 1.75rem;
    }
  
    .angular-description {
      font-size: 0.9rem;
    }
  
    .angular-header {
      height: 250px;
    }
  
    .angular-overlay {
      max-width: 95%;
      padding: 1rem;
    }
  }
  




  /* Angular JS Services Section Styles */
.angular-services-section {
    background-color: #f9f9f9; /* Light background for better readability */
    padding: 3rem 0;
  }
  
  .angular-services-heading {
    font-size: 2.5rem;
    font-weight: 600;
    color: #0e6dda;
  }
  
  .angular-services-lead {
    font-size: 1.2rem;
    font-weight: 400;
    color: #060606;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .angular-services-card {
    background-color: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .angular-services-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }
  
  .angular-services-card img {
    max-height: 100px;
    object-fit: contain;
    border-radius: 5px;
  }
  
  .angular-services-card h4 {
    font-size: 1.5rem;
    font-weight: 600;
    color: #0e6dda;
  }
  
  .angular-services-card p {
    font-size: 1rem;
    color: #666;
  }
  
  .angular-services-card .img-fluid {
    border-radius: 5px;
  }
  
  @media (max-width: 768px) {
    .angular-services-heading {
      font-size: 2rem;
    }
  
    .angular-services-card h4 {
      font-size: 1.2rem;
    }
  
    .angular-services-lead {
      font-size: 1rem;
    }
  }
  




  /* Angular.css */

/* Growth Section */
.angular-growth-section {
    background-color: #f8f9fa;
    padding: 2rem 0;
  }
  
  .angular-growth-heading {
    font-size: 2.5rem;
    color: #0e6dda;
    margin-bottom: 1.5rem;
  }
  
  .angular-growth-intro {
    font-size: 1.125rem;
    color: #6c757d;
    margin-bottom: 1.5rem;
  }
  
  .angular-growth-card {
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    transition: transform 0.3s ease;
  }
  
  .angular-growth-card:hover {
    transform: translateY(-5px);
  }
  
  .angular-growth-icon {
    color: #007bff; /* Adjust color to match your theme */
  }
  
  .angular-growth-icon i {
    font-size: 2rem;
  }
  
  .angular-growth-title {
    font-size: 1.25rem;
    color: #343a40;
    margin-top: 1rem;
  }
  
  .angular-growth-card p {
    font-size: 1rem;
    color: #495057;
  }
  
  .angular-growth-summary {
    font-size: 1.125rem;
    color: #6c757d;
    margin-top: 2rem;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .angular-growth-heading {
      font-size: 2rem;
    }
  
    .angular-growth-intro {
      font-size: 1rem;
    }
  
    .angular-growth-card {
      margin-bottom: 1rem;
    }
  
    .angular-growth-title {
      font-size: 1.125rem;
    }
  
    .angular-growth-card p {
      font-size: 0.875rem;
    }
  
    .angular-growth-summary {
      font-size: 1rem;
    }
  }
  
  @media (max-width: 576px) {
    .angular-growth-heading {
      font-size: 1.75rem;
    }
  
    .angular-growth-intro {
      font-size: 0.875rem;
    }
  
    .angular-growth-card {
      padding: 1rem;
    }
  
    .angular-growth-icon i {
      font-size: 1.5rem;
    }
  
    .angular-growth-title {
      font-size: 1rem;
    }
  
    .angular-growth-card p {
      font-size: 0.75rem;
    }
  
    .angular-growth-summary {
      font-size: 0.875rem;
    }
  }




  /* Advantages Section */
.angular-advantages-section {
    background-color: #f8f9fa;
    padding: 2rem 0;
  }
  
  .angular-advantages-heading {
    font-size: 2.5rem;
    color: #0e6dda;
    margin-bottom: 1.5rem;
  }
  
  .angular-advantages-card {
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    transition: transform 0.3s ease;
  }
  
  .angular-advantages-card:hover {
    transform: translateY(-5px);
  }
  
  .angular-advantages-icon {
    color: #007bff; /* Adjust color to match your theme */
  }
  
  .angular-advantages-icon i {
    font-size: 2rem;
  }
  
  .angular-advantages-title {
    font-size: 1.25rem;
    color: #343a40;
    margin-top: 1rem;
  }
  
  .angular-advantages-card p {
    font-size: 1rem;
    color: #495057;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .angular-advantages-heading {
      font-size: 2rem;
    }
  
    .angular-advantages-card {
      margin-bottom: 1rem;
    }
  
    .angular-advantages-title {
      font-size: 1.125rem;
    }
  
    .angular-advantages-card p {
      font-size: 0.875rem;
    }
  }
  
  @media (max-width: 576px) {
    .angular-advantages-heading {
      font-size: 1.75rem;
    }
  
    .angular-advantages-card {
      padding: 1rem;
    }
  
    .angular-advantages-icon i {
      font-size: 1.5rem;
    }
  
    .angular-advantages-title {
      font-size: 1rem;
    }
  
    .angular-advantages-card p {
      font-size: 0.75rem;
    }
  }
  