/* General Styles for the Page */
.customer-success {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #333;
}

/* Header Section Styles */
.customer-success-header {
  position: relative;
  padding: 80px 20px;
  background: linear-gradient(135deg, #007bff, #0056b3);
  color: white;
  text-align: center;
  overflow: hidden;
}

.customer-success-header::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1;
}

.header-content {
  position: relative;
  z-index: 2;
}

.customer-success-header h1 {
  font-size: 3em;
  margin-bottom: 0.5em;
  font-weight: 700;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.customer-success-header p {
  font-size: 1.3em;
  line-height: 1.5;
  margin: 0;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

/* Testimonials section */
.customer-success-testimonials {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 0 15px;
}

.testimonials-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.testimonial {
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.testimonial:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.testimonial-content {
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding: 20px;
}

.testimonial-text {
  flex: 1;
}

.testimonial-text p {
  font-size: 1rem;
  color: #888;
  line-height: 1.6;
}

.testimonial-text cite {
  font-size: 0.875rem;
  color: #080808;
  display: block;
  margin-top: 10px;
  font-weight: bold;
}

.testimonial-image {
  flex-shrink: 0;
  max-width: 200px;
}

.testimonial-image img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  object-fit: cover;
}

/* Responsive design */
@media (max-width: 768px) {
  .testimonials-grid {
      grid-template-columns: 1fr;
  }

  .testimonial-content {
      flex-direction: column;
      text-align: center;
  }

  .testimonial-image {
      margin: 0 auto;
  }
}
