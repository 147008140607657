/* Footer Base Styling */
.footer {
    background-color: #060606c8; /* Dark background */
    color: #fffefe; /* Light text color */
    padding: 1rem 0;
    position: relative;
    overflow: hidden;
}

/* General Text Styling */
.footer h1, .footer h4, .footer p {
    margin: 0;
    padding: 0;
}

.footer h1 {
    font-size: 2.5rem;
    font-weight: 700;
    color: #0e6dda;
}

.footer h4 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
}

.footer p {
    font-size: 1.2rem;
    line-height: 1.4;
    margin-top: 1rem; /* Space between paragraph and title */
}

/* Container Styling */
.footer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

/* Layout for Logo/Description and Contact Information */
.footer-top {
    display: flex;
    flex-wrap: wrap; /* Allow wrapping for smaller screens */
    justify-content: space-between;
    align-items: flex-start;
    width: 90%;
    max-width: 1000px;
    margin-bottom: 2rem; /* Space between top and social media */
    padding: 0 1rem;
}

.footer-logo-container, .footer-contact-container {
    text-align: center; /* Center content on small screens */
    flex: 1;
    min-width: 200px; /* Ensure minimum width for small screens */
    padding: 0 1rem; /* Add padding for spacing */
}

.footer-logo {
    font-size: 2.5rem;
    font-weight: 700;
    color: #0e6dda;
    margin-bottom: 1rem;
}

.footer-contact-container {
    margin-top: 2rem; /* Add space above the contact section on small screens */
}

.footer-contact {
    margin-top: 1rem; /* Space between contact info and other elements */
}

.footer-contact ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.footer-contact li {
    margin-bottom: 0.5rem;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center; /* Center icons and text */
}

.footer-contact .footer-icon {
    color: #0e6dda; /* Accent color for icons */
    margin-right: 0.5rem;
    font-size: 1.5rem; /* Adjust icon size */
}

/* Social media icon styling */
.social-icon {
    font-size: 2rem; /* Adjust size as needed */
    color: #0a0a0a; /* Default color for social media icons */
    transition: color 0.3s ease; /* Smooth transition for color change */
    position: relative; /* To position the dialogue box relative to the icon */
}
  
/* LinkedIn */
.social-icon.linkedin {
    color: #0077b5;
}
  
.social-icon.linkedin:hover {
    color: #005c99;
}
  
/* Email */
.social-icon.email {
    color: #c7c7c3;
}
  
.social-icon.email:hover {
    color: #555555;
}
  
/* Upwork - Vinod's Profile */
.social-icon.upwork {
    color: #00a77d;
}
  
.social-icon.upwork:hover {
    color: #008c6e;
}
  
.upwork-1:hover::after {
    content: "Vinod's Profile";
    position: absolute;
    bottom: 120%; /* Position above the icon */
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(113, 111, 111, 0.8); /* Background for the message */
    color: #fff;
    padding: 0.2rem 0.4rem; /* Reduced padding */
    font-size: 0.8rem; /* Smaller font size */
    border-radius: 2px;
    white-space: nowrap;
    z-index: 1000;
    opacity: 1;
    pointer-events: none;
    transition: opacity 0.3s ease;
}
  
/* Upwork - Ritika's Profile */
.social-icon.upwork-alt {
    color: #f71c08;
}
  
.social-icon.upwork-alt:hover {
    color: #f71c08;
}
  
.upwork-2:hover::after {
    content: "Ritika's Profile";
    position: absolute;
    bottom: 120%; /* Position above the icon */
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(113, 111, 111, 0.8); /* Background for the message */
    color: #fff;
    padding: 0.2rem 0.4rem; /* Reduced padding */
    font-size: 0.8rem; /* Smaller font size */
    border-radius: 2px;
    white-space: nowrap;
    z-index: 1000;
    opacity: 1;
    pointer-events: none;
    transition: opacity 0.3s ease;
}

/* Footer Bottom */
.footer-bottom {
    text-align: center;
    padding-top: 1rem;
    border-top: 1px solid #d6d4d4; /* Subtle border on top */
}

.footer-bottom p {
    font-size: 1rem;
    font-weight: bold;
    color: #fcfbfb;
}

/* Responsive Design */
@media (max-width: 1024px) {
    .footer-logo-container, .footer-contact-container {
        text-align: center; /* Center content on medium screens */
        min-width: auto; /* Remove minimum width constraint */
    }

    .footer-top {
        flex-direction: column; /* Stack logo and contact information vertically */
    }
}

@media (max-width: 768px) {
    .footer h1 {
        font-size: 2rem; /* Adjust font size for smaller screens */
    }

    .footer h4 {
        font-size: 1.2rem;
    }

    .footer p {
        font-size: 1rem;
    }

    .footer-contact-container {
        margin-top: 2rem; /* Increase space above contact section */
    }

    .footer-contact li {
        font-size: 1rem; /* Adjust font size for smaller screens */
    }

    .social-icon {
        font-size: 1.5rem; /* Adjust icon size */
    }
}

@media (max-width: 480px) {
    .footer h1 {
        font-size: 1.5rem; /* Further reduce font size for very small screens */
    }

    .footer h4 {
        font-size: 1rem;
    }

    .footer p {
        font-size: 0.9rem;
    }

    .footer-contact-container {
        margin-top: 2rem; /* Ensure sufficient space above contact section */
    }

    .footer-contact li {
        font-size: 0.9rem; /* Further adjust font size */
    }

    .social-icon {
        font-size: 1.2rem; /* Further reduce icon size */
    }
}
