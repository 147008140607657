/* General Styles */
.field-service-container {
    
    background-color: #f5f7f9;
    color: #333;
    padding: 20px;
}

/* Keyframe Animation for Header Fade-in and Slide-up */
@keyframes fadeInSlideUp {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Header Section */
.field-service-header {
    background-image: url('../Services pages/images/field2.jpg');
    background-size: cover;
    background-position: center;
    height: 400px; /* Set the height to whatever you want */
    padding: 60px 20px; /* Adjust padding if needed */
    text-align: center;
    border-radius: 15px;
    color: #fff;
    position: relative;
    overflow: hidden;
}


.field-service-header::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Adjust the color and opacity here */
    z-index: 1;
}

.field-service-header-content {
    position: relative;
    z-index: 2;
    animation: fadeInSlideUp 1s ease-out;
}

.field-service-header h1,
.field-service-header p {
    opacity: 0;
    transform: translateY(20px);
}

.field-service-header h1 {
    font-size: 3.5em;
    margin-bottom: 15px;
    font-weight: 800;
    animation: fadeInSlideUp 1s ease-out 0.5s forwards;
}

.field-service-header p {
    font-size: 1.3em;
    margin-bottom: 30px;
    animation: fadeInSlideUp 1s ease-out 1s forwards;
}


/* Combined Content Section */
.field-service-combined {
    padding: 80px 20px;
    background: #fff;
    border-radius: 20px;
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
    margin: 60px auto;
    max-width: 1200px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.field-service-combined::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 40%;
    height: 100%;
    background: url('../Services pages/images/field8.jpg') no-repeat center center;
    background-size: cover;
    opacity: 0.1;
    z-index: 1;
}

.field-service-text-wrapper {
    position: relative;
    max-width: 900px;
    text-align: center;
    z-index: 2;
}

.field-service-text-wrapper h2 {
    font-size: 2.8em;
    color: #0e6dda;
    margin-bottom: 20px;
    font-weight: 700;
}

.field-service-text-wrapper p {
    font-size: 1.2em;
    color: #040404;
    line-height: 1.8;
    margin-bottom: 20px;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .field-service-header {
        padding: 80px 20px;
    }

    .field-service-header h1 {
        font-size: 2.5em;
    }

    .field-service-header p {
        font-size: 1em;
    }

    .field-service-combined {
        padding: 40px 15px;
    }

    .field-service-text-wrapper h2 {
        font-size: 2em;
    }

    .field-service-text-wrapper p {
        font-size: 1em;
    }
}

/* Section Styles */
.field-service-section {
    display: flex;
    padding: 60px 20px;
    background-color: #ffffff;
    border-radius: 15px;
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
    margin-top: 40px;
    align-items: center;
}

.field-service-section-image {
    flex: 1;
    margin-right: 20px;
}

.field-service-section-image img {
    width: 90%;
    border-radius: 15px;
    
}

.field-service-section-text {
    flex: 1;
}

.field-service-section-text h2 {
    font-size: 2.5em;
    color: #0e6dda;
    margin-bottom: 20px;
    font-weight: 700;
}

.field-service-section-text p {
    font-size: 1.2em;
    color: #030303;
    line-height: 1.8;
}

/* New Section */
.field-service-new {
    background-color: #f8f9fc;
    padding: 60px 20px;
    border-radius: 15px;
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
    margin-top: 40px;
    text-align: center;
}

.field-service-new h2 {
    font-size: 2.5em;
    color: #0e6dda;
    margin-bottom: 20px;
    font-weight: 700;
}

.field-service-new p {
    font-size: 1.2em;
    line-height: 1.8;
    margin-bottom: 20px;
}

.field-service-detail {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 15px;
    margin-bottom: 20px;
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.field-service-detail h4 {
    font-size: 1.8em;
    color: #333;
    margin-bottom: 10px;
    font-weight: 700;
}

.field-service-detail p {
    font-size: 1.2em;
    line-height: 1.8;
    margin-bottom: 0;
}

.field-service-detail:hover {
    transform: translateY(-10px);
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.3);
}


/* Responsive Styles */
@media (max-width: 1024px) {
    .field-service-header {
        padding: 100px 20px;
    }

    .field-service-header h1 {
        font-size: 3em;
    }

    .field-service-header p {
        font-size: 1.1em;
    }

    .field-service-combined {
        padding: 60px 20px;
    }

    .field-service-text-wrapper h2 {
        font-size: 2.5em;
    }

    .field-service-text-wrapper p {
        font-size: 1.1em;
    }
}

@media (max-width: 768px) {
    .field-service-header {
        padding: 80px 10px;
    }

    .field-service-header h1 {
        font-size: 2.5em;
    }

    .field-service-header p {
        font-size: 1em;
    }

    .field-service-combined {
        padding: 40px 15px;
    }

    .field-service-text-wrapper h2 {
        font-size: 2em;
    }

    .field-service-text-wrapper p {
        font-size: 1em;
    }
}

@media (max-width: 576px) {
    .field-service-header {
        padding: 60px 10px;
    }

    .field-service-header h1 {
        font-size: 2em;
    }

    .field-service-header p {
        font-size: 0.9em;
    }

    .field-service-combined {
        padding: 30px 10px;
    }

    .field-service-text-wrapper h2 {
        font-size: 1.8em;
    }

    .field-service-text-wrapper p {
        font-size: 0.9em;
    }

    .field-service-section {
        flex-direction: column;
        padding: 40px 10px;
    }

    .field-service-section-image {
        margin-right: 0;
        margin-bottom: 20px;
    }

    .field-service-section-image img {
        width: 100%;
    }

    .field-service-section-text h2 {
        font-size: 2em;
    }

    .field-service-section-text p {
        font-size: 1em;
    }

    .field-service-new h2 {
        font-size: 2em;
    }

    .field-service-new p {
        font-size: 1em;
    }

    .field-service-detail h4 {
        font-size: 1.6em;
    }

    .field-service-detail p {
        font-size: 1em;
    }
}
