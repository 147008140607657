/* General Navbar styling */
.navbar {
  background-color: #f8f9fa; /* Light background for navbar */
  border-bottom: 1px solid #e0e0e0; /* Subtle border at the bottom */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add a soft shadow */
}

/* Navbar brand styling */
.navbar-brand {
  font-size: 2rem; /* Larger font size for the brand */
  font-weight: bold;
  background: linear-gradient(135deg, #0e6dda, #007bff); /* Gradient text */
  -webkit-background-clip: text; /* Clip the background to text */
  -webkit-text-fill-color: transparent; /* Make the text fill transparent */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1); /* Add a soft text shadow */
  transition: all 0.4s ease; /* Smooth transition for all properties */
  position: relative;
}

/* Hover effect with text expansion and color change */
.navbar-brand:hover {
  color: #101010; /* Fallback text color on hover */
  transform: scale(1.05); /* Slightly enlarge the text on hover */
  text-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2); /* More pronounced shadow on hover */
  background: linear-gradient(135deg, #0e6dda, #f89a5f); /* Reverse the gradient on hover */
  -webkit-background-clip: text; /* Reapply text clip */
  -webkit-text-fill-color: transparent; /* Ensure transparency is maintained */
}

/* Main navbar links styling */
.navbar-nav .nav-link {
  font-size: 1.1rem; /* Slightly larger font size */
  font-weight: 500;
  color: #0a0a0a; /* Neutral color for links */
  margin-right: 15px; /* Space between nav items */
  transition: color 0.3s ease, padding 0.3s ease; /* Smooth transitions */
}

.navbar-nav .nav-link:hover {
  color: whitesmoke; /* Link color on hover */
  padding: 0.5rem 0.75rem; /* Add padding on hover for a button-like effect */
  background-color: #0e6dda; /* Light background on hover */
  border-radius: 4px; /* Rounded corners on hover */
}

/* Navbar Collapse styling */
.navbar-collapse {
  justify-content: flex-end; /* Align items to the right */
}


/* Dropdown menu styling for Company */
.companyDropdown .dropdown-menu {
  background-color: whitesmoke;
  border: 1px solid #070707;
  border-radius: 8px; /* Slightly more rounded corners */
  padding: 1rem;
  min-width: 230px;
  max-width: 100vw;
  max-height: 400px;
  position: absolute;
  left: -130px;
  z-index: 1000;
  opacity: 0; /* Start hidden */
  visibility: hidden; /* Hidden by default */
  transform: translateY(-10px); /* Start position */
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out, visibility 0.3s ease-in-out; /* Smooth transitions */
}

/* Show dropdown menu when hovering over Company */
.companyDropdown:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

/* Dropdown menu styling for Services */
.servicesDropdown .dropdown-menu {
  background-color: whitesmoke;
  border: 1px solid #070707;
  border-radius: 8px; /* Slightly more rounded corners */

  padding: 1rem;
  min-width: 300px; /* Adjust minimum width for better fit on small screens */
  max-width: 100vw; /* Ensure dropdown does not exceed viewport width */
  max-height: 400px; /* Set a max-height for dropdown content */
  position: absolute;
  left: -750px; /* Adjust position to fit within the viewport */
  top: 100%;
  z-index: 1000;
  overflow-y: auto; /* Enable vertical scrolling if content overflows */
  opacity: 0; /* Start hidden */
  visibility: hidden; /* Hidden by default */
  transform: translateY(-10px); /* Start position */
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out, visibility 0.3s ease-in-out; /* Smooth transitions */
}

/* Show dropdown menu when hovering over Services */
.servicesDropdown:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

/* Dropdown section header */
.dropdown-header {
  font-weight: bold; /* Bold header text */
  margin-bottom: 0.5rem; /* Space below header */
  color: #0e6dda; /* Add a color to section headers */
}

/* Dropdown items styling */
.dropdown-item {
  padding: 0.5rem 1rem; /* Comfortable padding */
  color: #060606; /* Neutral text color */
  text-decoration: none; /* Remove underline from links */
  border-radius: 4px; /* Rounded corners */
  
  transition: background 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease; /* Smooth transitions */
  box-shadow: #0e6dda; /* Subtle shadow for depth */
}

/* Hover effect with soft gradient and gentle lift */
.dropdown-item:hover {
  background: linear-gradient(135deg, #1e61f3cf, #e9ecef); /* Soft gradient background on hover */
  color: #faf7f7; /* Change text color on hover */
  font-style: bold;
  box-shadow: 0 4px 6px rgb(24, 129, 249); /* Slightly more pronounced shadow */
  transform: translateY(-2px); /* Gentle lift on hover */
}

/* Dropdown menu sections */
.dropdown-sections {
  display: flex;
  flex-direction: row; /* Horizontal layout */
  justify-content: space-between; /* Equal gaps between sections */
}

/* Dropdown section styling */
.dropdown-section {
  flex: 1; /* Each section takes equal width */
  padding: 0 10px; /* Padding inside each section */
  box-sizing: border-box; /* Include padding in width */
}



/* Responsive adjustments */
@media (max-width: 992px) {
  .navbar-collapse {
    flex-direction: column; /* Stack nav items vertically on small screens */
  }

  .navbar-nav {
    margin-top: 1rem; /* Space between navbar and content */
  }

  .dropdown-menu {
    position: static; /* Ensure dropdown is not absolute on small screens */
    width: 100%; /* Full width of the screen */
    opacity: 1; /* Always visible in static position */
    visibility: visible; /* Always visible in static position */
    transform: none; /* No transform needed in static position */
    transition: none; /* Disable transition effects in static position */
  }

  .companyDropdown .dropdown-menu,
  .servicesDropdown .dropdown-menu {
    left: 0; /* Reset left position for mobile */
    top: auto; /* Reset top position */
  }

  .dropdown-sections {
    flex-direction: column; /* Stack sections vertically on small screens */
    align-items: stretch; /* Make sections full-width */
  }

  .dropdown-section {
    padding: 1rem 0; /* Adjust padding for mobile */
    border-bottom: 1px solid #ced4da; /* Add a border between sections */
  }

  .dropdown-section:last-child {
    border-bottom: none; /* Remove border from the last section */
  }
}

@media (max-width: 576px) {
  .servicesDropdown .dropdown-menu {
    min-width: 100%; /* Ensure dropdown fits the viewport width on extra small screens */
    left: 0; /* Reset left position */
    top: auto; /* Reset top position */
  }

  .dropdown-item {
    padding: 0.75rem 1rem; /* Adjust padding for extra small screens */
  }

  .dropdown-header {
    font-size: 1rem; /* Adjust font size for extra small screens */
  }
}







