/* Adjust the main content container on all pages */
body {
  padding-top: 70px; /* Adjust this value to match the height of your fixed navbar */
}

/* Header Section Styling */
.commerce-cloud-header {
  position: relative;
  height: 500px;
  background: url('../Services pages/images/commerce.jpg') no-repeat center center/cover;
  background-attachment: fixed;
  background-size: cover;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.commerce-cloud-header-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  background-color: #333333c5;
  padding: 20px 20px;
  border-radius: 20px;
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.6);
}

.commerce-cloud-header-overlay h1 {
  font-size: 2.5rem;
  font-weight: bold;
  color: white;
  margin-bottom: 20px;
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
  animation: fadeIn 2s ease-out;
}

.commerce-cloud-header-overlay p {
  font-size: 1.2rem;
  color: white;
  font-family: 'Roboto', sans-serif;
  line-height: 1.5;
  animation: fadeIn 2s ease-out;
}

@keyframes fadeIn {
  from {
      opacity: 0;
      transform: translateY(20px);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
}

/* Media Queries for Header Section */
@media (max-width: 576px) {
  .commerce-cloud-header {
    height: 300px;
  }

  .commerce-cloud-header-overlay h1 {
    font-size: 2rem;
  }

  .commerce-cloud-header-overlay p {
    font-size: 1.2rem;
  }
}

@media (max-width: 768px) {
  .commerce-cloud-header {
    height: 400px;
  }

  .commerce-cloud-header-overlay h1 {
    font-size: 2.5rem;
  }

  .commerce-cloud-header-overlay p {
    font-size: 1.5rem;
  }
}






/* Highlight Section Styling */
.commerce-cloud-highlight-section {
  display: flex;
  align-items: center;
  padding: 100px 50px;
  background: linear-gradient(to right, #ffffff, #f1f1f1);
  position: relative;
}

.commerce-cloud-highlight-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  filter: blur(10px);
  z-index: 0;
}

.commerce-cloud-image {
  flex: 1;
  position: relative;
  z-index: 1;
}

.commerce-cloud-image img {
  max-width: 100%;
  border-radius: 20px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.3);
  transition: transform 0.5s ease, filter 0.5s ease;
}

.commerce-cloud-image img:hover {
  transform: scale(1.1);
  filter: brightness(0.8);
}

.commerce-cloud-content {
  flex: 2;
  margin-left: 40px;
  z-index: 1;
}

.commerce-cloud-content h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #0e6dda;
  font-family: 'Poppins', sans-serif;
  position: relative;
}

.commerce-cloud-content h2::after {
  content: '';
  display: block;
  width: 60px;
  height: 5px;
  background: #0e6dda;
  margin: 15px auto;
  border-radius: 5px;
}

.commerce-cloud-content p {
  font-size: 1.2rem;
  color: #333;
  line-height: 1.6;
}

/* Media Queries for Highlight Section */
@media (max-width: 576px) {
  .commerce-cloud-highlight-section {
    flex-direction: column;
    padding: 50px 20px;
  }

  .commerce-cloud-image {
    margin-bottom: 20px;
  }

  .commerce-cloud-content {
    margin-left: 0;
  }
}

@media (max-width: 768px) {
  .commerce-cloud-highlight-section {
    padding: 70px 30px;
  }

  .commerce-cloud-content {
    margin-left: 20px;
  }
}








/* Detailed Information Section */
.commerce-cloud-info-section {
  padding: 80px 40px;
  background: radial-gradient(circle, #ffffff, #f8f9fa);
  position: relative;
}

.commerce-cloud-info-section h2 {
  font-size: 2.5rem;
  color: #0e6dda;
  margin-bottom: 20px;
  text-align: center;
  font-family: 'Poppins', sans-serif;
}

.commerce-cloud-info-section p {
  font-size: 1.2rem;
  color: #495057;
  margin-bottom: 30px;
  text-align: center;
}

.commerce-cloud-cards-container {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
}

.commerce-cloud-info-card {
  background: #ffffff;
  border-radius: 15px;
  padding: 30px;
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.2);
  flex: 1 1 300px;
  position: relative;
  overflow: hidden;
  transition: transform 0.4s ease, box-shadow 0.4s ease;
  cursor: pointer;
}

.commerce-cloud-info-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: #0e6dda;
  transition: width 0.4s ease;
}

.commerce-cloud-info-card:hover::before {
  width: 100%;
}

.commerce-cloud-info-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 20px 60px #0e6dda;
}

.commerce-cloud-info-card h3 {
  font-size: 1.8rem;
  margin-bottom: 15px;
  font-family: 'Poppins', sans-serif;
  color: #0a0a0a;
}

.commerce-cloud-info-card p {
  font-size: 1.1rem;
  color: #333;
}

/* Media Queries for Detailed Information Section */
@media (max-width: 576px) {
  .commerce-cloud-info-section {
    padding: 40px 20px;
  }

  .commerce-cloud-info-card {
    flex: 1 1 100%;
  }

  .commerce-cloud-info-card h3 {
    font-size: 1.5rem;
  }

  .commerce-cloud-info-card p {
    font-size: 1rem;
  }
}

@media (max-width: 768px) {
  .commerce-cloud-info-section {
    padding: 60px 30px;
  }

  .commerce-cloud-info-card {
    flex: 1 1 45%;
  }

  .commerce-cloud-info-card h3 {
    font-size: 1.7rem;
  }

  .commerce-cloud-info-card p {
    font-size: 1.1rem;
  }
}








/* New Section */
.commerce-cloud-new-section {
  padding: 80px 40px;
  background: linear-gradient(to bottom, #f9f9f9, #ffffff);
}

.commerce-cloud-new-section h2 {
  font-size: 2.5rem;
  color: #0e6dda;
  margin-bottom: 20px;
  text-align: center;
  font-family: 'Poppins', sans-serif;
}

.commerce-cloud-new-section p {
  font-size: 1.2rem;
  color: #495057;
  margin-bottom: 30px;
  text-align: center;
}

.commerce-cloud-new-section-details {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
}

.commerce-cloud-new-section-detail-item {
  background: #ffffff;
  border-radius: 15px;
  padding: 30px;
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.2);
  flex: 1 1 300px;
  position: relative;
  overflow: hidden;
  transition: transform 0.4s ease, box-shadow 0.4s ease;
  cursor: pointer;
}

.commerce-cloud-new-section-detail-item::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  height: 100%;
  background: #007bff;
  transition: height 0.4s ease;
}

.commerce-cloud-new-section-detail-item:hover::before {
  height: 100%;
}

.commerce-cloud-new-section-detail-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 20px 60px #007bff;
}

.commerce-cloud-new-section-detail-item h3 {
  font-size: 1.8rem;
  margin-bottom: 15px;
  font-family: 'Poppins', sans-serif;
  color: #0a0a0a;
}

.commerce-cloud-new-section-detail-item p {
  font-size: 1.1rem;
  color: #333;
}

/* Media Queries for New Section */
@media (max-width: 576px) {
  .commerce-cloud-new-section {
    padding: 40px 20px;
  }

  .commerce-cloud-new-section-detail-item {
    flex: 1 1 100%;
  }

  .commerce-cloud-new-section-detail-item h3 {
    font-size: 1.5rem;
  }

  .commerce-cloud-new-section-detail-item p {
    font-size: 1rem;
  }
}

@media (max-width: 768px) {
  .commerce-cloud-new-section {
    padding: 60px 30px;
  }

  .commerce-cloud-new-section-detail-item {
    flex: 1 1 45%;
  }

  .commerce-cloud-new-section-detail-item h3 {
    font-size: 1.7rem;
  }

  .commerce-cloud-new-section-detail-item p {
    font-size: 1.1rem;
  }
}








/* Benefits Section */
.commerce-cloud-benefits-section {
  padding: 80px 40px;
  background: #f9f9f9;
}

.commerce-cloud-benefits-section h2 {
  font-size: 2.5rem;
  color: #0e6dda;
  margin-bottom: 20px;
  text-align: center;
  font-family: 'Poppins', sans-serif;
}

.commerce-cloud-benefits-section p {
  font-size: 1rem;
  color: #495057;
  margin-bottom: 30px;
  text-align: center;
}

.commerce-cloud-benefits-container {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
}

.commerce-cloud-benefit-item {
  background: #ffffff;
  border-radius: 15px;
  padding: 30px;
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.2);
  flex: 1 1 300px;
  position: relative;
  overflow: hidden;
  transition: transform 0.4s ease, box-shadow 0.4s ease;
  cursor: pointer;
}

.commerce-cloud-benefit-item::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  height: 0;
  background: #007bff;
  transition: height 0.4s ease;
}

.commerce-cloud-benefit-item:hover::before {
  height: 100%;
}

.commerce-cloud-benefit-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 20px 60px #007bff;
}

.commerce-cloud-benefit-item h3 {
  font-size: 1.5rem;
  margin-bottom: 15px;
  font-family: 'Poppins', sans-serif;
  color: #007bff;
}

.commerce-cloud-benefit-item p {
  font-size: 1.2rem;
  color: #333;
}

/* Media Queries for Benefits Section */
@media (max-width: 576px) {
  .commerce-cloud-benefits-section {
    padding: 40px 20px;
  }

  .commerce-cloud-benefit-item {
    flex: 1 1 100%;
  }

  .commerce-cloud-benefit-item h3 {
    font-size: 1.5rem;
  }

  .commerce-cloud-benefit-item p {
    font-size: 1rem;
  }
}

@media (max-width: 768px) {
  .commerce-cloud-benefits-section {
    padding: 60px 30px;
  }

  .commerce-cloud-benefit-item {
    flex: 1 1 45%;
  }

  .commerce-cloud-benefit-item h3 {
    font-size: 1.7rem;
  }

  .commerce-cloud-benefit-item p {
    font-size: 1.1rem;
  }
}
