/* Adjust the main content container on all pages */
body {
    padding-top: 70px; /* Adjust this value to match the height of your fixed navbar */
  }

/* General Styles */
.sales-dx-container {
    
    margin: 0;
    padding: 0;
}

/* Header Section */
.sales-dx-header {
    background-image: url('../Services pages/images/dx.png'); /* Adjust the path to your image */
    background-size: cover;
    background-position: center;
    padding: 120px 20px;
    text-align: center;
    border-radius: 15px;
    color: #fff;
    position: relative;
    overflow: hidden;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.sales-dx-header::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Overlay color */
    z-index: 1;
}

.sales-dx-header-content {
    position: relative;
    z-index: 2;
}

.sales-dx-header h1 {
    font-size: 2.5em;
    margin-bottom: 15px;
    font-weight: 700;
    letter-spacing: 1px;
}

.sales-dx-header p {
    font-size: 1.2em;
    margin-bottom: 30px;
    line-height: 1.5;
}

/* Developer Productivity Section */
.sales-dx-productivity {
    background: #f8f9fa;
    padding: 40px 20px;
    border-radius: 10px;
    margin-bottom: 30px;
    display: flex;
    align-items: flex-start;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.sales-dx-content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.sales-dx-image {
    width: 40%;
    max-width: 350px;
    border-radius: 10px;
    margin-right: 20px; /* Adds space between image and text */
    margin-top: -60px; /* Moves the image up */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.sales-dx-text {
    flex: 1;
}

.sales-dx-productivity h2 {
    font-size: 2.2rem;
    color: #007bff;
    margin-bottom: 20px;
    font-weight: 600;
}

.sales-dx-productivity p {
    font-size: 1.2rem;
    line-height: 1.6;
    margin-bottom: 0;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
    .sales-dx-header, .sales-dx-productivity, .sales-dx-services, .sales-dx-features {
        text-align: center;
        padding: 30px 15px;
    }
    
    .sales-dx-content {
        flex-direction: column;
        align-items: center;
    }

    .sales-dx-image {
        width: 80%;
        margin-bottom: 20px;
        margin-top: 0; /* Remove upward movement on smaller screens */
    }

    .sales-dx-header h1, .sales-dx-productivity h2 {
        font-size: 2.5rem;
    }

    .sales-dx-header p, .sales-dx-productivity p {
        font-size: 1.1rem;
    }
}

/* Services Section */
.sales-dx-services {
    background: #fff;
    padding: 40px 20px;
    border-radius: 10px;
    margin-bottom: 30px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.sales-dx-services h2 {
    font-size: 2.2rem;
    color: #007bff;
    margin-bottom: 30px;
    text-align: center;
    font-weight: 600;
}

.sales-dx-cards {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.sales-dx-card {
    background: #f8f9fa;
    border-radius: 10px;
    padding: 20px;
    flex: 1;
    text-align: left;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
}

.sales-dx-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.sales-dx-card h3 {
    font-size: 1.6rem;
    color: #007bff;
    margin-bottom: 15px;
    font-weight: 600;
}

.sales-dx-card p {
    font-size: 1.1rem;
    line-height: 1.6;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
    .sales-dx-header, .sales-dx-productivity, .sales-dx-services {
        text-align: center;
        padding: 30px 15px;
    }
    
    .sales-dx-content {
        flex-direction: column;
        align-items: center;
    }

    .sales-dx-image {
        width: 80%;
        margin-bottom: 20px;
        margin-top: 0; /* Remove upward movement on smaller screens */
    }

    .sales-dx-header h1, .sales-dx-productivity h2, .sales-dx-services h2 {
        font-size: 2.2rem;
    }

    .sales-dx-header p, .sales-dx-productivity p, .sales-dx-card p {
        font-size: 1rem;
    }

    .sales-dx-cards {
        flex-direction: column;
    }

    .sales-dx-card {
        margin-bottom: 20px;
    }
}

/* Features of Services Section */
.sales-dx-features {
    background: #f1f1f1;
    padding: 40px 20px;
    border-radius: 10px;
    margin-bottom: 30px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.sales-dx-features h2 {
    font-size: 2.2rem;
    color: #007bff;
    margin-bottom: 30px;
    text-align: center;
    font-weight: 600;
}

.sales-dx-feature-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.sales-dx-feature {
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    text-align: left;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.sales-dx-feature h3 {
    font-size: 1.6rem;
    color: #007bff;
    margin-bottom: 15px;
    font-weight: 600;
}

.sales-dx-feature p {
    font-size: 1.1rem;
    line-height: 1.6;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
    .sales-dx-header, .sales-dx-productivity, .sales-dx-services, .sales-dx-features {
        text-align: center;
        padding: 30px 15px;
    }

    .sales-dx-content {
        flex-direction: column;
        align-items: center;
    }

    .sales-dx-image {
        width: 80%;
        margin-bottom: 20px;
        margin-top: 0; /* Reset upward movement on smaller screens */
    }

    .sales-dx-header h1, .sales-dx-productivity h2, .sales-dx-services h2, .sales-dx-features h2 {
        font-size: 2rem;
    }

    .sales-dx-header p, .sales-dx-productivity p, .sales-dx-card p, .sales-dx-feature p {
        font-size: 1rem;
    }

    .sales-dx-cards, .sales-dx-feature-list {
        flex-direction: column;
    }

    .sales-dx-card, .sales-dx-feature {
        margin-bottom: 20px;
    }
}
