/* General Styles */
body {
  padding-top: 70px; /* Adjust this value to match the height of your fixed navbar */
  margin: 0; /* Ensure there is no default margin affecting layout */
}

/* Universal Box-Sizing Fix */
* {
  box-sizing: border-box;
}

/* General Styles */
.salescloud-container {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #333;
  line-height: 1.6;
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal overflow */
}

/* Header Section */
.salescloud-header-section {
  position: relative;
  width: 100%;
  height: 70vh; /* Adjust height as needed */
  overflow: hidden; /* Prevent overflow */
  display: flex; /* Flexbox for centering */
  align-items: center; /* Vertically center */
  justify-content: center; /* Horizontally center */
  text-align: center;
  color: white;
  padding-top: 70px; /* Match the fixed navbar height */
  box-sizing: border-box; /* Ensure padding is included in element's total width and height */
}

.salescloud-header-image {
  position: absolute; /* Make sure image covers the entire section */
  width: 100%;
  height: 100%;
  object-fit: cover;
  top: 0;
  left: 0;
  z-index: -1; /* Ensure image stays behind the overlay */
}

.salescloud-header-text-overlay {
  position: relative; /* Position relative to its container */
  background: rgba(0, 0, 0, 0.6);
  padding: 20px;
  border-radius: 12px;
  width: 80%;
  max-width: 700px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  z-index: 1; /* Ensure text is on top of the image */
}

.salescloud-header-title {
  font-size: 2.5rem; /* Adjusted font size */
  margin-bottom: 15px;
  font-weight: 700;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
}

.salescloud-header-subtitle {
  font-size: 1.2rem; /* Adjusted font size */
  font-weight: 300;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4);
}

/* Media Queries */
@media (max-width: 992px) {
  .salescloud-header-title {
    font-size: 2rem;
  }

  .salescloud-header-subtitle {
    font-size: 1rem;
  }
}

@media (max-width: 768px) {
  .salescloud-header-title {
    font-size: 1.8rem;
  }

  .salescloud-header-subtitle {
    font-size: 0.9rem;
  }

  .salescloud-header-text-overlay {
    width: 70%; /* Adjust width for smaller screens */
    padding: 15px; /* Adjust padding for smaller screens */
  }
}

@media (max-width: 576px) {
  .salescloud-header-title {
    font-size: 1.5rem;
  }

  .salescloud-header-subtitle {
    font-size: 0.8rem;
  }

  .salescloud-header-text-overlay {
    width: 80%; /* Further adjust width for very small screens */
    padding: 10px; /* Further adjust padding for very small screens */
  }
}


/* Main Content Section */
.salescloud-main-content {
  padding: 40px 20px;
  max-width: 1200px; /* Centering max-width */
  margin: 0 auto; /* Centering container */
}

.salescloud-main-heading {
  text-align: center;
  font-size: 2rem; /* Adjusted font size */
  margin-bottom: 30px;
  font-weight: 600;
  color: #007bff;
}

.salescloud-content-cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.salescloud-content-card {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  margin: 15px;
  width: 100%;
  max-width: 320px;
  text-align: center;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s, box-shadow 0.3s;
}

.salescloud-content-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 24px #0e6dda;
}

.salescloud-card-icon {
  font-size: 2rem; /* Adjusted font size */
  color: #007bff;
}

.salescloud-card-title {
  font-size: 1.4rem; /* Adjusted font size */
  margin: 15px 0;
  font-weight: 600;
}

.salescloud-card-text {
  font-size: 0.9rem; /* Adjusted font size */
  color: #666;
}

/* Media Queries */
@media (max-width: 992px) {
  .salescloud-main-heading {
    font-size: 1.8rem;
  }

  .salescloud-content-card {
    width: 100%;
    max-width: 290px; /* Adjust for medium screens */
  }
}

@media (max-width: 768px) {
  .salescloud-main-heading {
    font-size: 1.6rem;
  }

  .salescloud-content-card {
    width: 100%;
    max-width: 260px; /* Adjust for smaller screens */
  }
}

@media (max-width: 576px) {
  .salescloud-main-heading {
    font-size: 1.4rem;
  }

  .salescloud-content-card {
    width: 100%;
    max-width: 100%; /* Full width for very small screens */
    margin: 10px 0; /* Adjust margin for very small screens */
  }
}

/* Services Section */
.salescloud-services-section {
  padding: 40px 20px;
  background-color: #f1f1f1;
  max-width: 1200px; /* Centering max-width */
  margin: 0 auto; /* Centering container */
}

.salescloud-services-heading {
  text-align: center;
  font-size: 2rem; /* Adjusted font size */
  margin-bottom: 30px;
  font-weight: 600;
  color: #007bff;
}

.salescloud-services-cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center the cards */
}

.salescloud-service-card {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  margin: 15px;
  width: 100%;
  max-width: 320px;
  text-align: center;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s, box-shadow 0.3s;
}

.salescloud-service-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 24px #0e6dda;
}

.salescloud-service-icon {
  font-size: 2rem; /* Adjusted font size */
  color: #007bff;
}

.salescloud-service-title {
  font-size: 1.4rem; /* Adjusted font size */
  margin: 15px 0;
  font-weight: 600;
}

.salescloud-service-text {
  font-size: 0.9rem; /* Adjusted font size */
  color: #666;
}

/* Media Queries */
@media (max-width: 992px) {
  .salescloud-services-heading {
    font-size: 1.8rem;
  }

  .salescloud-service-card {
    width: 100%;
    max-width: 290px; /* Adjust for medium screens */
  }
}

@media (max-width: 768px) {
  .salescloud-services-heading {
    font-size: 1.6rem;
  }

  .salescloud-service-card {
    width: 100%;
    max-width: 260px; /* Adjust for smaller screens */
  }
}

@media (max-width: 576px) {
  .salescloud-services-heading {
    font-size: 1.4rem;
  }

  .salescloud-service-card {
    width: 100%;
    max-width: 100%; /* Full width for very small screens */
    margin: 10px 0; /* Adjust margin for very small screens */
  }
}

/* Why Choose Us Section */
.salescloud-why-choose-us {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px 20px;
  background: #fff;
  max-width: 1200px; /* Centering max-width */
  margin: 0 auto; /* Centering container */
}

.salescloud-why-choose-us-text {
  width: 50%;
  padding-right: 20px;
}

.salescloud-why-choose-us-img {
  width: 50%;
}

.salescloud-why-choose-us-title {
  font-size: 2rem; /* Adjusted font size */
  margin-bottom: 20px;
  font-weight: 600;
}

.salescloud-why-choose-us-text p {
  font-size: 1rem; /* Adjusted font size */
  line-height: 1.6;
  color: #666;
}

/* Media Queries */
@media (max-width: 768px) {
  .salescloud-why-choose-us {
    flex-direction: column;
    text-align: center;
  }

  .salescloud-why-choose-us-text {
    width: 100%;
    padding-right: 0;
    margin-bottom: 20px;
  }

  .salescloud-why-choose-us-img {
    width: 100%;
  }

  .salescloud-why-choose-us-title {
    font-size: 1.6rem;
  }

  .salescloud-why-choose-us-text p {
    font-size: 0.9rem;
  }
}

/* Footer Section */
.salescloud-footer {
  padding: 20px;
  background-color: #007bff;
  color: #fff;
  text-align: center;
}

.salescloud-footer p {
  font-size: 0.9rem; /* Adjusted font size */
  margin: 10px 0;
}

.salescloud-footer a {
  color: #fff;
  text-decoration: none;
  font-weight: 600;
}

.salescloud-footer a:hover {
  text-decoration: underline;
}

/* Additional Styles */
.salescloud-carousel {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.salescloud-carousel img {
  width: 100%;
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.salescloud-carousel-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  border: none;
  color: #fff;
  padding: 10px;
  cursor: pointer;
  border-radius: 50%;
  font-size: 1.5rem;
}

.salescloud-carousel-button.left {
  left: 10px;
}

.salescloud-carousel-button.right {
  right: 10px;
}

.salescloud-carousel-button:hover {
  background: rgba(0, 0, 0, 0.7);
}
